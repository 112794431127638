// pageService.ts

import {
  Nomination,
  NominationField,
  NominationWithApplications,
  SubNomination,
} from "../admin/types";
import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

// Helper function to handle fetch errors
const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function getNomination(id: string): Promise<Nomination> {
  try {
    const response = await fetch(`${BASE_URL}/nominations/${id}`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Nomination;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function getNominations(): Promise<Nomination[]> {
  try {
    const response = await fetch(`${BASE_URL}/nominations`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Nomination[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function getNominationsWithApplications(): Promise<
  NominationWithApplications[]
> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/nominations/applications`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as NominationWithApplications[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function getNominationsWithApplicationsPublic(): Promise<
  NominationWithApplications[]
> {
  try {
    const response = await fetch(`${BASE_URL}/nominations/applicationsPublic`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as NominationWithApplications[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function postNomination(body: any): Promise<Nomination> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/nominations`,
      "POST",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Nomination;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function putNomination(
  id: string,
  body: any
): Promise<Nomination> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/nominations/${id}`,
      "PUT",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Nomination;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function getSubNominations(
  nominationId: string
): Promise<SubNomination[]> {
  try {
    const response = await fetch(
      `${BASE_URL}/nominations/${nominationId}/subNominations`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as SubNomination[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export const postSubNomination = async (
  subNomination: SubNomination,
  nomination: Nomination
): Promise<SubNomination> => {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/sub-nominations`,
      "POST",
      { ...subNomination, nomination: { ...nomination } }
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as SubNomination;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
};

export async function putSubNomination(
  id: string,
  body: any
): Promise<SubNomination> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/sub-nominations/${id}`,
      "PUT",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as SubNomination;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function postFieldToNomination(
  nominationId: string,
  fieldId: string
): Promise<NominationField> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/nominations/${nominationId}/add-field/${fieldId}`,
      "POST"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as NominationField;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function deleteFieldToNomination(
  nominationId: string,
  fieldId: string
): Promise<NominationField> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/nominations/${nominationId}/remove-field/${fieldId}`,
      "DELETE"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as NominationField;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}
