// pageService.ts

import { ApplicationLike, Block } from "../admin/types";
import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

// Helper function to handle fetch errors
const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function getBlocks(): Promise<Block[]> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/schedule`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Block[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function postBlock(body: any): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/schedule/create-block`,
      "POST",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function putBlock(id: string, body: any): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/schedule/update-block/${id}`,
      "PUT",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function delBlock(id: string): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/schedule/delete-block/${id}`,
      "DELETE"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function moveBlock(
  blockId: string,
  previousBlockId?: string | null
): Promise<void> {
  let url = `${BASE_URL}/schedule/move-block/${blockId}/${previousBlockId}`;
  if (previousBlockId === null) {
    url = `${BASE_URL}/schedule/move-block/${blockId}`;
  }
  try {
    const response = await fetchWithAuth(url, "PUT");

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function deleteScheduleItem(id: string): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/schedule/delete-schedule-item/${id}`,
      "DELETE"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function postScheduleItem(
  blockId: string,
  id: string
): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/schedule/create-schedule-item`,
      "POST",
      {
        applicationId: id,
        blockId: blockId,
      }
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function moveScheduleItem(
  itemId: string,
  previousItemId: string
): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/schedule/move-schedule-item/${itemId}/${previousItemId}`,
      "PUT"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function setLikeScheduleItem(itemId: string): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/voting/toggleVote/${itemId}`,
      "POST"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function getLikesSummary(): Promise<ApplicationLike[]> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/voting/getStageVoteSummary`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as ApplicationLike[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}
