import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function postSetting(
  key: string,
  value: string
): Promise<boolean> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/configs/${key}/${value}`,
      "GET"
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    return true;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function getSetting(key: string): Promise<string> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/configs/${key}`, "GET");

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}
