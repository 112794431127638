import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Nomination } from "../types";
import {
  deleteFieldToNomination,
  getNomination,
  getNominations,
  postFieldToNomination,
} from "../../services/nominationsService";
import { getFields } from "../../services/fieldsService";

function NominationsFields() {
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const [currentNomination, setCurrentNomination] = useState<Nomination>();
  const [nominationFields, setNominationFields] = useState<Field[]>([]);
  const [fields, setFields] = useState<Field[]>([]);

  const getAllFields = async (alreadyAddedFields: Field[]) => {
    const allFields = await getFields();
    setFields(
      allFields.filter(
        (f) => !alreadyAddedFields.map((af) => af.fieldId).includes(f.fieldId)
      )
    );
  };

  const getAllNominations = async () => {
    const nominationsData = await getNominations();
    setNominations(nominationsData);
  };

  const getCurrentNomination = async (nominationId?: string) => {
    if (nominationId) {
      const nomination = await getNomination(nominationId);
      setCurrentNomination(nomination);
      const nomFields =
        nomination.nominationFields
          ?.sort((a, b) => a.order - b.order)
          .map((f) => f.field) ?? [];
      setNominationFields(nomFields);
      getAllFields(nomFields);
    }
  };

  const addFieldToNomination = async (
    nominationId?: string,
    fieldId?: string
  ) => {
    if (nominationId && fieldId) {
      await postFieldToNomination(nominationId, fieldId);
      getCurrentNomination(nominationId);
    }
  };

  const removeFieldFromNomination = async (
    nominationId?: string,
    fieldId?: string
  ) => {
    if (nominationId && fieldId) {
      await deleteFieldToNomination(nominationId, fieldId);
      getCurrentNomination(nominationId);
    }
  };

  useEffect(() => {
    getAllNominations();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
        <h1>Основные номинации</h1>
        <List>
          {nominations.map((nom) => (
            <ListItem key={nom.nominationId}>
              <ListItemButton
                onClick={() => {
                  getCurrentNomination(nom.nominationId);
                }}
              >
                <ListItemText primary={nom.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {currentNomination && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1>Поля для {currentNomination.name}</h1>
          <List style={{ overflowY: "scroll" }}>
            {nominationFields.map((nom) => (
              <ListItem key={nom.fieldId}>
                <ListItemButton
                  onClick={() => {
                    removeFieldFromNomination(
                      currentNomination.nominationId,
                      nom.fieldId
                    );
                  }}
                >
                  <ListItemText primary={nom.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      )}
      {currentNomination && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1>Доступные поля</h1>
          <List style={{ overflowY: "scroll" }}>
            {fields.map((nom) => (
              <ListItem key={nom.fieldId}>
                <ListItemButton
                  onClick={() => {
                    addFieldToNomination(
                      currentNomination.nominationId,
                      nom.fieldId
                    );
                  }}
                >
                  <ListItemText primary={nom.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default NominationsFields;
