import React, { ReactNode, useEffect, useState } from "react";
import "./Profile.css";
import { UserProfile } from "../types/UserProfile";
import { getProfile } from "../services/auth.service";
import { getUserPhotoLink } from "../utils/authUtils";

function Profile() {
  const [profileData, setProfileData] = useState<UserProfile>();

  useEffect(() => {
    async function fetchProfile() {
      try {
        const profile = await getProfile();
        setProfileData(profile);
      } catch (error) {
        // Handle error
        console.error("Error fetching profile:", error);
      }
    }

    fetchProfile();
  }, []);

  const saveProfile = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
  };

  return (
    <div className="contest longread">
      {profileData ? (
        <div className="content-section container-v center">
          <div className="content longread-content">
            <h1>МОЙ ПРОФИЛЬ</h1>
            <h2>Ваши данные</h2>
            <h3 style={{ width: "50%", margin: "10px 0px" }}>
              <p>АВТОЗАПОЛНЕНИЕ ЗАЯВКИ</p>
              Указанные ниже данные будут автоматически заполняться в раздел
              "Ваши данные" при подаче заявки в сценические номинации.
            </h3>
            <div className="container" style={{ width: "100%" }}>
              <form
                style={{ width: "60%", alignItems: "flex-start" }}
                onSubmit={(e) => saveProfile(e)}
              >
                <div
                  className="container-v"
                  style={{ width: "100%", gap: "15px" }}
                >
                  <div
                    className="container"
                    style={{
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div className={`container-v field`}>
                      <label className="text field-label" htmlFor="nickname">
                        Никнейм <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="nickname"
                        name="nickname"
                        defaultValue={profileData.nickname}
                      />
                    </div>
                    <div className={`container-v field`}>
                      <label
                        className="text field-label"
                        htmlFor="nickname-spelling"
                      >
                        Транскрипция никнейма <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="nickname-spelling"
                        name="nickname-spelling"
                        defaultValue={profileData.nicknameSpelling}
                      />
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div className={`container-v field`}>
                      <label className="text field-label" htmlFor="real-name">
                        ФИО <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="real-name"
                        name="real-name"
                        defaultValue={profileData.realName}
                      />
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      gap: "20px",
                      width: "50%",
                    }}
                  >
                    <div className={`container-v field`}>
                      <label className="text field-label" htmlFor="city">
                        Город <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        defaultValue={profileData.city}
                      />
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div className={`container-v field`}>
                      <label className="text field-label" htmlFor="phone">
                        Мобильный телефон <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        defaultValue={profileData.phoneNumber}
                      />
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div className={`container-v field`}>
                      <label className="text field-label" htmlFor="email">
                        Email <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        defaultValue={profileData.email}
                      />
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div className={`container-v field`}>
                      <label className="text field-label" htmlFor="vk">
                        Адрес страницы VK <br />{" "}
                      </label>
                      <input
                        type="text"
                        id="vk"
                        name="vk"
                        defaultValue={profileData.vkAddress}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="text button apply-button wrap">
                  СОХРАНИТЬ
                </button>
              </form>
              <div
                className="container"
                style={{ width: "40%", justifyContent: "center" }}
              >
                <img
                  src={getUserPhotoLink() ?? "/Userpic.png"}
                  alt="Userpic"
                  className="userpic"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
