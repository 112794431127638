import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../config";

const LoginFromVk = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const uid = searchParams.get("uid");
    const firstName = searchParams.get("first_name");
    const lastName = searchParams.get("last_name");
    const photo = searchParams.get("photo");
    const photoRec = searchParams.get("photo_rec");
    const hash = searchParams.get("hash");

    // You can send the data to the backend using a POST request
    fetch(`${BASE_URL}/auth/login-from-vk`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid,
        first_name: firstName,
        last_name: lastName,
        photo,
        photo_rec: photoRec,
        hash,
        // Add any other required data here
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Handle successful login
          return response.json();
        } else {
          // Handle login error
          throw new Error("Login failed");
        }
      })
      .then((data) => {
        // Handle the response data, e.g., save access token to local storage
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("uid-vk", uid ?? "");
        // Redirect to the main page
        window.location.href = "/"; // You can change this to the actual main page URL
      })
      .catch((error) => {
        console.error("Login error:", error);
        // Handle login error, e.g., display an error message

        // Redirect to the main page (you can customize the URL as needed)
        window.location.href = "/"; // Change this to the actual main page URL
      });
  }, [searchParams]);

  return (
    <div style={{ height: "100vh" }}>
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default LoginFromVk;
