import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PhotocosplayAdmin } from "../types";
import { getPhotocosplayAdmin } from "../../services/applicationsService";

function PhotocosplayAdminPage() {
  const [photocosplayData, setPhotocosplayData] =
    useState<PhotocosplayAdmin[]>();

  useEffect(() => {
    async function fetchPage() {
      try {
        const photocosplayData = await getPhotocosplayAdmin();
        setPhotocosplayData(
          photocosplayData.sort((a, b) => b.rating - a.rating)
        );
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "100%" }}>
      <Box sx={{ width: "700px", padding: "10px" }}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID заявки</TableCell>
                <TableCell align="left">Выступление</TableCell>
                <TableCell align="left">Рейтинг</TableCell>
                <TableCell align="left">Количество голосов</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {photocosplayData?.map((row) => (
                <TableRow
                  key={row.applicationId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.applicationId}
                  </TableCell>
                  <TableCell align="left">{row.fullName}</TableCell>
                  <TableCell align="left" title={row.rating.toString()}>
                    {row.rating.toFixed(3)}
                  </TableCell>
                  <TableCell align="left">{row.voteCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default PhotocosplayAdminPage;
