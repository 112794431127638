import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

const ImagePreviewWithPopUp = ({ url }: { url: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <div onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer" }}>
        <img className="thumbnail" src={url} alt={url} />
      </div>
      {isModalOpen && (
        <div
          id="modal_overlay"
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            id="modal"
            style={{
              maxWidth: "90vw",
              height: "90vh",
              backgroundColor: "white",
              padding: "20px",
            }}
          >
            <div className="container" style={{ height: "100%" }}>
              <div
                className="image-container"
                style={{ height: "100%", width: "auto" }}
              >
                <img
                  className="image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    display: "inline-block",
                  }}
                  src={url}
                  alt={url}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePreviewWithPopUp;
