import {
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Snackbar,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import {
  Block,
  MyApplication,
  Nomination,
  NominationWithApplications,
  ScheduleItem,
} from "../types";
import {
  delBlock,
  deleteScheduleItem,
  getBlocks,
  moveBlock,
  moveScheduleItem,
  postBlock,
  postScheduleItem,
  putBlock,
} from "../../services/scheduleService";
import { getNominationsWithApplications } from "../../services/nominationsService";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getApplicationById } from "../../services/applicationsService";

function BlocksPage() {
  const [block, setBlock] = useState<Block>();
  const [editedBlock, setEditedBlock] = useState<Block>();
  const [duration, setDuration] = useState<number>(5);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [nominations, setNominations] = useState<NominationWithApplications[]>(
    []
  );
  const [currentBlock, setCurrentBlock] = useState<Block>();
  const [currentApplication, setCurrentApplication] = useState<any>();
  const [currentApplicationId, setCurrentApplicationId] = useState<string>();
  const [blockSnackbarOpen, setBlockSnackbarOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllNominations = async () => {
    const nominationsData = await getNominationsWithApplications();
    setNominations(nominationsData);
  };

  const addNewBlock = async (newBlock: Block) => {
    setIsLoading(true);
    await postBlock(newBlock);
    setBlock(undefined);
    getAllBlocks();
    setIsLoading(false);
  };

  const getAllBlocks = async () => {
    const blocksData = await getBlocks();
    let time = new Date(2023, 1, 1, 12, 0, 0, 0).getTime();
    const orderedBlocks = blocksData
      .sort((a, b) => a.order - b.order)
      .map((b) => {
        const startTime = new Date(time);
        let newBlock = {
          ...b,
          duration: b.durationInSeconds / 60,
          startTime: `${startTime.getHours()}:${
            startTime.getMinutes() < 10
              ? "0" + startTime.getMinutes()
              : startTime.getMinutes()
          }`,
        };
        time += b.durationInSeconds * 1000;
        return newBlock;
      });
    setBlocks(orderedBlocks);
  };

  const saveBlock = async (block: Block) => {
    setIsLoading(true);
    await putBlock(block.blockId ?? "", block);
    setEditedBlock(undefined);
    getAllBlocks();
    setIsLoading(false);
  };

  const deleteBlock = async (block: Block) => {
    if (block.scheduleItems?.length === 0 && block.blockId) {
      setIsLoading(true);
      await delBlock(block.blockId);
      await getAllBlocks();
      setIsLoading(false);
    } else {
      setBlockSnackbarOpen(true);
    }
  };

  const removeScheduleItem = async (item: ScheduleItem) => {
    if (item.scheduleItemId) {
      setIsLoading(true);
      await deleteScheduleItem(item.scheduleItemId);
      await getAllBlocks();
      setIsLoading(false);
    }
  };

  const addScheduleItem = async (blockId: string, itemId: string) => {
    setIsLoading(true);
    await postScheduleItem(blockId, itemId);
    await getAllBlocks();
    setIsLoading(false);
  };

  const editBlock = (block: Block) => {
    setEditedBlock(block);
  };

  const moveBlockOneSpace = async (
    blockId: string,
    direction: "up" | "down"
  ) => {
    setIsLoading(true);
    const currentIndex = blocks.findIndex((b) => b.blockId === blockId);
    if (currentIndex !== -1) {
      if (direction === "up" && currentIndex !== 0) {
        let previousBlockId = null;
        if (currentIndex !== 1) {
          previousBlockId = blocks[currentIndex - 2].blockId ?? "";
        }

        await moveBlock(blockId, previousBlockId);
        await getAllBlocks();
      }
      if (direction === "down" && currentIndex !== blocks.length - 1) {
        const previousBlockId = blocks[currentIndex + 1].blockId ?? "";
        await moveBlock(blockId, previousBlockId);
        await getAllBlocks();
      }
    }
    setIsLoading(false);
  };

  const moveScheduleItemOneSpace = async (
    blockId: string,
    itemId: string,
    direction: "up" | "down"
  ) => {
    setIsLoading(true);
    const currentIndex = blocks
      .find((b) => b.blockId === blockId)
      ?.scheduleItems.findIndex((i) => i.scheduleItemId === itemId);
    if (currentIndex !== undefined && currentIndex !== -1) {
      if (direction === "up" && currentIndex !== 0) {
        const previousItemId =
          blocks.find((b) => b.blockId === blockId)?.scheduleItems[
            currentIndex - 1
          ].scheduleItemId ?? "";
        await moveScheduleItem(itemId, previousItemId);
        await getAllBlocks();
      }
      if (direction === "down" && currentIndex !== blocks.length - 1) {
        const previousItemId =
          blocks.find((b) => b.blockId === blockId)?.scheduleItems[
            currentIndex + 1
          ].scheduleItemId ?? "";
        await moveScheduleItem(itemId, previousItemId);
        await getAllBlocks();
      }
    }
    setIsLoading(false);
  };

  const getApplicationData = async (applicationId: string) => {
    setIsLoading(true);
    setCurrentApplicationId(applicationId);
    const applicationData = await getApplicationById(applicationId);
    const fieldCodes = [
      "schedule-wish",
      "extra_info",
      "defile-start",
      "props",
      "performance",
    ];
    const neededData = applicationData.filter((a: any) =>
      fieldCodes.includes(a.field.code)
    );
    setCurrentApplication(neededData);
    setIsLoading(false);
  };

  const getAvailableItems = () => {
    const allNominationItems = nominations
      .find((n) => n.nominationId === currentBlock?.nominationId)
      ?.subNominations.map((s) => {
        return [...s.applications.filter((a) => a.state === 4)];
      })
      .reduce((a, b) => a.concat(b), []);

    const scheduledNominationItems = blocks
      .filter((b) => b.nominationId === currentBlock?.nominationId)
      .map((b) => {
        return [...b.scheduleItems];
      })
      .reduce((a, b) => a.concat(b), [])
      .map((i) => i.applicationId);

    const availableItems = allNominationItems?.filter(
      (i) => !scheduledNominationItems.includes(i.applicationId)
    );
    return (
      <List style={{ width: "100%", overflowY: "scroll", height: "60%" }}>
        {availableItems?.map((i) => (
          <ListItem
            key={i.applicationId}
            style={{
              padding: "0px",
            }}
          >
            <ListItemButton
              style={{
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px",
              }}
              selected={currentApplicationId === i.applicationId}
              onClick={() => getApplicationData(i.applicationId)}
            >
              <ListItemText primary={`${i.fullName}`} />
              <Button
                color="secondary"
                variant="outlined"
                onClick={() =>
                  addScheduleItem(
                    currentBlock?.blockId ?? "",
                    i.applicationId ?? ""
                  )
                }
              >{`Add to ${currentBlock?.name} ${
                blocks
                  .filter(
                    (bl) => bl.nominationId === currentBlock?.nominationId
                  )
                  .findIndex((bl) => bl.blockId === currentBlock?.blockId) + 1
              } блок`}</Button>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  useEffect(() => {
    getAllBlocks();
    getAllNominations();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div
        style={{
          width: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading && (
          <div className="loading-overlay" style={{ position: "absolute" }}>
            <div className="spinner"></div>
          </div>
        )}
        <h1>Добавить блок</h1>
        <FormControl fullWidth>
          <InputLabel htmlFor="nomination">Номинация</InputLabel>
          <Select
            label="Nomination"
            id="nomination"
            value={block?.nominationId ?? ""}
            onChange={(event) => {
              setBlock({
                name: `${
                  nominations.find((n) => n.nominationId === event.target.value)
                    ?.name ?? "Новый блок"
                }`,
                order:
                  blocks.length !== 0
                    ? (blocks[blocks.length - 1]?.order ?? 0) + 1
                    : 0,
                nominationId:
                  nominations.find((n) => n.nominationId === event.target.value)
                    ?.nominationId ?? nominations[0].nominationId,
                duration: duration,
                durationInSeconds: duration * 60,
                scheduleItems: [],
              });
            }}
          >
            {nominations.map((nom) => (
              <MenuItem key={nom.nominationId} value={nom.nominationId}>
                {nom.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div className="container-v" style={{ flex: 1 }}>
            <div>Название блока</div>
            <TextField
              value={block?.name ?? ""}
              onChange={(event) => {
                if (block) {
                  setBlock({ ...block, name: event.target.value });
                }
              }}
            />
          </div>
          {block?.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91" && (
            <div className="container-v text center">{`${
              blocks.filter((b) => b.nominationId === block?.nominationId)
                .length + 1
            } блок`}</div>
          )}
          <div className="container-v" style={{ flex: 0.3 }}>
            <div>Длительность</div>
            <TextField
              value={block?.duration ?? ""}
              onChange={(event) => {
                if (!isNaN(parseInt(event.target.value))) {
                  setDuration(parseInt(event.target.value));
                  if (block) {
                    setBlock({
                      ...block,
                      duration: parseInt(event.target.value),
                      durationInSeconds: parseInt(event.target.value) * 60,
                    });
                  }
                } else {
                  setDuration(0);
                  if (block) {
                    setBlock({
                      ...block,
                      duration: 0,
                      durationInSeconds: 0,
                    });
                  }
                }
              }}
            />
          </div>
        </div>
        <Button
          style={{ margin: "10px" }}
          onClick={() => {
            if (block) {
              addNewBlock(block);
            }
          }}
          title="Добавить"
        >
          <AddIcon />
          Добавить
        </Button>
        <Divider />

        <List style={{ overflowY: "scroll" }}>
          {blocks.map((b, index) => (
            <ListItem
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.8)",
                padding: "0px",
                flexDirection: "column",
                marginTop: "5px",
              }}
              key={b.blockId}
            >
              <div
                className="container"
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderBottom: "#202020 2px solid",
                }}
              >
                <ListItemButton
                  style={{
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                  selected={currentBlock?.blockId === b.blockId}
                  onClick={() => {
                    setCurrentBlock(b);
                  }}
                >
                  <ListItemText
                    primary={`${b.startTime} ${b.name} ${
                      b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
                        ? blocks
                            .filter((bl) => bl.nominationId === b.nominationId)
                            .findIndex((bl) => bl.blockId === b.blockId) + 1
                        : ""
                    }  ${
                      b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
                        ? "блок"
                        : ""
                    } (${b.duration} минут)`}
                  />
                  <IconButton
                    color="error"
                    style={{ minWidth: "0px" }}
                    onClick={() => deleteBlock(b)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                  <Snackbar
                    open={blockSnackbarOpen}
                    onClose={() => setBlockSnackbarOpen(false)}
                    message="Невозможно удалить! Блок должен быть пустым!"
                  />
                  <IconButton
                    style={{ minWidth: "0px" }}
                    onClick={() => editBlock(b)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{ minWidth: "0px" }}
                    onClick={() => moveBlockOneSpace(b.blockId ?? "", "up")}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    style={{ minWidth: "0px" }}
                    onClick={() => moveBlockOneSpace(b.blockId ?? "", "down")}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </ListItemButton>
              </div>
              {editedBlock?.blockId === b.blockId && (
                <div
                  className="container-v"
                  style={{
                    padding: "10px",
                    borderBottom: "#202020 2px solid",
                  }}
                >
                  <h3>Редактировать блок</h3>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div className="container-v" style={{ flex: 1 }}>
                      <div>Название блока</div>
                      <TextField
                        defaultValue={b?.name ?? ""}
                        onChange={(event) => {
                          if (b) {
                            setEditedBlock({
                              ...b,
                              name: event.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="container-v" style={{ flex: 0.3 }}>
                      <div>Длительность</div>
                      <TextField
                        defaultValue={b?.duration}
                        onChange={(event) => {
                          setDuration(parseInt(event.target.value));
                          if (b) {
                            setEditedBlock({
                              ...b,
                              duration: parseInt(event.target.value),
                              durationInSeconds:
                                parseInt(event.target.value) * 60,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{ justifyContent: "space-around" }}
                  >
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        if (editedBlock) {
                          saveBlock(editedBlock);
                        }
                      }}
                      title="Сохранить"
                    >
                      Сохранить
                    </Button>
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setEditedBlock(undefined);
                      }}
                      title="Отмена"
                    >
                      Отмена
                    </Button>
                  </div>
                </div>
              )}
              <List style={{ width: "100%" }}>
                {b.scheduleItems.map((i, index) => (
                  <ListItem
                    key={i.scheduleItemId}
                    style={{
                      padding: "0px 0px 0px 30px",
                    }}
                  >
                    <ListItemButton
                      style={{
                        justifyContent: "space-between",
                        gap: "10px",
                        padding: "0px",
                      }}
                    >
                      <ListItemText primary={`${index + 1}. ${i.name}`} />
                      <IconButton
                        style={{ minWidth: "0px" }}
                        onClick={() =>
                          moveScheduleItemOneSpace(
                            b.blockId ?? "",
                            i.scheduleItemId,
                            "up"
                          )
                        }
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        style={{ minWidth: "0px" }}
                        onClick={() =>
                          moveScheduleItemOneSpace(
                            b.blockId ?? "",
                            i.scheduleItemId,
                            "down"
                          )
                        }
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                      <IconButton
                        style={{ minWidth: "0px" }}
                        onClick={() => removeScheduleItem(i)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          ))}
        </List>
      </div>
      <Divider orientation="vertical" />
      {currentBlock && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "700px",
          }}
        >
          <h1>Доступные выступления for {currentBlock.name}</h1>
          {getAvailableItems()}
          <Divider />
          <div
            className="container-v"
            style={{ overflowY: "scroll", height: "40%" }}
          >
            {currentApplication?.map((a: any) => (
              <div className="container-v" key={a.applicationDataId}>
                <div className="text">
                  <strong>{a.field.label}</strong>
                </div>
                <div
                  className="regular-text"
                  style={{ marginBottom: "5px", color: "#fff" }}
                >
                  {a.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default BlocksPage;
