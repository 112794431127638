import React, { ReactNode, useEffect, useState } from "react";
import "./Photocosplay.css";
import { Photocosplay } from "../admin/types";
import {
  getPhotocosplay,
  setPhotocosplayRating,
} from "../services/applicationsService";
import PhotocosplayPreviewWithPopUp from "../components/PhotocosplayPreviewWithPopUp";

function PhotocosplayVote() {
  const [photocosplayData, setPhotocosplayData] = useState<Photocosplay[]>();

  useEffect(() => {
    async function fetchPage() {
      try {
        const photocosplayData = await getPhotocosplay();
        setPhotocosplayData(
          photocosplayData.sort((a, b) =>
            a.applicationId.localeCompare(b.applicationId)
          )
        );
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
  }, []);

  const gePhotocosplayCard = (ph: Photocosplay): ReactNode => {
    return (
      <PhotocosplayPreviewWithPopUp
        photocosplay={ph}
        ratingChanged={ratingChanged}
      />
    );
  };

  const ratingChanged = async (applicationId: string, newRating: number) => {
    const photocosplay = photocosplayData?.find(
      (d) => d.applicationId === applicationId
    );
    if (photocosplay && photocosplayData) {
      photocosplay.rating = newRating;

      setPhotocosplayData(
        [
          ...photocosplayData.filter((f) => f.applicationId !== applicationId),
          photocosplay,
        ].sort((a, b) => a.applicationId.localeCompare(b.applicationId))
      );
    }
    await setPhotocosplayRating(applicationId, newRating);
  };

  return (
    <div className="contest longread">
      {photocosplayData ? (
        <div className="content-section container-v center">
          <div className="content longread-content">
            <h1>ФОТОКОСПЛЕЙ</h1>
            <h2>Голосование</h2>
            <div
              className="container photocosplay-page"
              style={{ width: "100%", marginTop: "20px" }}
            >
              {photocosplayData.map((ph) => {
                return gePhotocosplayCard(ph);
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhotocosplayVote;
