// pageService.ts

import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

export type Page = {
  pageId?: string;
  route: string;
  content: string;
  title: string;
  order?: number;
};

// Helper function to handle fetch errors
const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function getPage(route: string): Promise<Page> {
  try {
    route = route[0] === "/" ? route.substring(1, route.length) : route;
    const response = await fetch(
      `${BASE_URL}/pages/${route.replaceAll("/", "%2F")}`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Page;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function getPages(): Promise<Page[]> {
  try {
    const response = await fetch(`${BASE_URL}/pages`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Page[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function postPage(body: any): Promise<Page> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/pages`, "POST", body);

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Page;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function putPage(id: string, body: any): Promise<Page> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/pages/${id}`,
      "PUT",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Page;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}
