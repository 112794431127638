import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Page } from "../types";
import { Outlet } from "react-router-dom";

const pages: Page[] = [
  {
    name: "Дедлайны",
    link: "deadlines",
  },
  {
    name: "Страницы",
    link: "info",
  },
  {
    name: "Расписание",
    link: "schedule",
  },
];

function SiteContent() {
  return (
    <div style={{ marginTop: "70px", display: "flex", height: "100%" }}>
      <Box sx={{ width: "300px" }}>
        <List>
          {pages.map((page) => (
            <ListItem key={page.link}>
              <ListItemButton href={`/admin/site-content/${page.link}`}>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider orientation="vertical" />
      <Outlet />
    </div>
  );
}

export default SiteContent;
