import React from "react";
import "./FooterSection.css";

const FooterSection = () => {
  return (
    <div className="content-section footer-section container center">
      <div className="text footer-text" style={{ display: "none" }}>
        ИНН и еще какая-нибудь полезная инфа
      </div>
      <div className="text footer-age-text" style={{ marginLeft: "auto" }}>
        12+
      </div>
    </div>
  );
};

export default FooterSection;
