import React, { useEffect, useState } from "react";
import { getBlocks, setLikeScheduleItem } from "../services/scheduleService";
import { Block, ScheduleItem } from "../admin/types";
import { isAccessTokenValid } from "../utils/authUtils";
import "./SchedulePage.css";

function SchedulePage() {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<
    "schedule" | "map" | "info" | "likes"
  >("schedule");

  const getAllBlocks = async () => {
    setIsLoading(true);
    const blocksData = await getBlocks();
    let time = new Date(2023, 1, 1, 12, 0, 0, 0).getTime();
    const orderedBlocks = blocksData
      .sort((a, b) => a.order - b.order)
      .map((b) => {
        const startTime = new Date(time);
        let newBlock = {
          ...b,
          duration: b.durationInSeconds / 60,
          startTime: `${startTime.getHours()}:${
            startTime.getMinutes() < 10
              ? "0" + startTime.getMinutes()
              : startTime.getMinutes()
          }`,
        };
        time += b.durationInSeconds * 1000;
        return newBlock;
      });
    setBlocks(orderedBlocks);
    setIsLoading(false);
  };

  const getHeader = (title: string, color: "blue" | "gray", time?: string) => {
    return (
      <div className={`container schedule-header schedule-header-${color}`}>
        <div className="schedule-header-title">{title}</div>
        {time && <div className="schedule-header-title">{time}</div>}
      </div>
    );
  };

  const getScheduleItem = (item: ScheduleItem) => {
    return (
      <div key={item.scheduleItemId} className="container schedule-item">
        <div className="container-v" style={{ gap: "10px" }}>
          <div className="schedule-item-title">{item.name.split(" — ")[1]}</div>
          <div className="schedule-item-subtitle">
            {item.name.split(" — ")[0]}
          </div>
        </div>
        {isAccessTokenValid() && (
          <div className="container center">
            {item.liked ? (
              <img
                className="schedule-like"
                src="pink-heart.svg"
                alt="Liked"
                onClick={() => likeScheduleItem(item)}
              />
            ) : (
              <img
                className="schedule-like"
                src="heart.svg"
                alt="Like"
                onClick={() => likeScheduleItem(item)}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const likeScheduleItem = (item: ScheduleItem) => {
    setLikeScheduleItem(item.applicationId);
    const blocksUpdated = [...blocks];
    const itemUpdate = blocksUpdated
      .find((b) => b.scheduleItems.includes(item))
      ?.scheduleItems.find((i) => i === item);
    if (itemUpdate) {
      itemUpdate.liked = !item.liked;
    }
    setBlocks(blocksUpdated);
  };

  const getSchedule = () => {
    let blockIndex = 0;
    return (
      <div className="container-v">
        {!isAccessTokenValid() ? (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Для участия в зрительском голосовании необходимо авторизоваться на
            сайте!
          </div>
        ) : (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Ставьте лайки понравившимся выступлениям! Участники с наибольшим
            количеством лайков получат призы зрительских симпатий!
          </div>
        )}
        {blocks.map((b) => {
          return (
            <div className="container-v">
              {getHeader(b.name, "gray", b.startTime)}
              {(b.name === "Открытие" || b.name === "Перерыв") &&
                getHeader(`${(blockIndex = blockIndex + 1)} Блок`, "blue")}
              {b.scheduleItems?.length > 0 &&
                b.scheduleItems.map((i) => {
                  return getScheduleItem(i);
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const getLikes = () => {
    const likes: any[] = [];
    blocks.forEach((b) => {
      const likedItems = b.scheduleItems.filter((i) => i.liked);
      if (likedItems.length > 0) {
        const nomination = likes.find((l) => l.name === b.name);
        if (nomination === undefined) {
          likes.push({
            name: b.name,
            likes: likedItems,
          });
        } else {
          nomination.likes = [...nomination.likes, ...likedItems];
        }
      }
    });
    return (
      <div className="container-v">
        {!isAccessTokenValid() ? (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Для участия в зрительском голосовании необходимо авторизоваться на
            сайте!
          </div>
        ) : (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Выступления, которые вам понравились
          </div>
        )}
        {likes.map((l) => {
          return (
            <div className="container-v">
              {getHeader(l.name, "gray")}
              {l.likes.map((li: any) => {
                return getScheduleItem(li);
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const getScheduleMenu = (
    page: "schedule" | "map" | "info" | "likes",
    iconUrl: string
  ) => {
    return (
      <div
        className={`container center schedule-header-${
          currentPage === page ? "blue" : "gray"
        }`}
        style={{ flex: 1, padding: "9px", cursor: "pointer" }}
        onClick={() => {
          setCurrentPage(page);
        }}
      >
        <img
          className="schedule-like"
          src={currentPage === page ? `white-${iconUrl}` : iconUrl}
          alt={page}
        />
      </div>
    );
  };

  const getMap = () => {
    return (
      <div className="container-v">
        <div
          className="schedule-item-title container center"
          style={{ fontSize: "22px", margin: "17px" }}
        >
          Схема фестиваля
        </div>
        <img src="fest-map-min.png" alt="NYAF" style={{ margin: "10px" }} />
      </div>
    );
  };

  const getInfo = () => {
    return (
      <div className="container-v">
        <div
          className="schedule-item-title container-v center"
          style={{
            fontSize: "16px",
            margin: "10px 17px 5px 17px",
            textAlign: "center",
          }}
        >
          Фестиваль пройдет по адресу ДК Орджоникидзе, ул. Первомайская, 14
        </div>
        <div
          className="container"
          style={{ justifyContent: "space-around", alignItems: "center" }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://yandex.ru/maps/172/ufa/house/pervomayskaya_ulitsa_14/YU8YdgFoSkYHQFtufXRweHhkYg==/?ll=56.068331%2C54.819477&z=16.74"
          >
            <img
              className="map-link"
              style={{ maxWidth: "30px" }}
              src="yandex-maps-small.png"
              alt="Yandex Maps"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://2gis.ru/ufa/firm/70000001053152040?m=56.067977%2C54.819463%2F16"
          >
            <img
              className="map-link"
              style={{ maxWidth: "35px" }}
              src="2gis-maps-small.png"
              alt="2GIS Maps"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/place/%D0%A3%D1%84%D0%B8%D0%BC%D1%81%D0%BA%D0%B8%D0%B9+%D0%B3%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B9+%D0%BD%D0%B5%D1%84%D1%82%D1%8F%D0%BD%D0%BE%D0%B9+%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9+%D1%83%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82/@54.8189089,56.0667345,17.52z/data=!4m15!1m8!3m7!1s0x43d9208bea4fba89:0xba34be4fd6a74a5!2z0J_QtdGA0LLQvtC80LDQudGB0LrQsNGPINGD0LsuLCAxNCwg0KPRhNCwLCDQoNC10YHQvy4g0JHQsNGI0LrQvtGA0YLQvtGB0YLQsNC9LCDQoNC-0YHRgdC40Y8sIDQ1MjE1MQ!3b1!8m2!3d54.5492947!4d55.747959!16s%2Fg%2F11lkd1bb4q!3m5!1s0x43d937e0f227c583:0x31b3dd7600febccc!8m2!3d54.8193271!4d56.0686152!16s%2Fm%2F09rx5hr?entry=ttu"
          >
            <img
              className="map-link"
              style={{ maxWidth: "30px" }}
              src="google-maps-small.png"
              alt="Google Maps"
            />
          </a>
        </div>
        <div
          className="schedule-item-title container-v center"
          style={{ fontSize: "20px", margin: "17px" }}
        >
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              9:00
            </div>
            <div style={{ flex: "1" }}>Вход для участников и помощников</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              11:00
            </div>
            <div style={{ flex: "1" }}>
              Вход для гостей, старт продажи билетов
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              12:00
            </div>
            <div style={{ flex: "1" }}>Начало сценической программы</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              20:00
            </div>
            <div style={{ flex: "1" }}>Окончание сценической программы</div>
          </div>
        </div>
        <a
          className="info-link"
          href="https://vk.com/afterparty_nyaf"
          target="_blank"
          rel="noreferrer"
        >
          AFTERPARTY, 18+
        </a>
        <div
          className="schedule-item-title container-v center"
          style={{
            fontSize: "16px",
            margin: "5px 17px 20px 17px",
            textAlign: "center",
          }}
        >
          Афтерпати фестиваля пройдет в воскресенье, 17 декабря, по адресу ул.
          Коммунистическая, 50.
        </div>
        <div className="header" style={{ textAlign: "center" }}>
          НЕ ПРОПУСТИТЕ
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "5px" }}
        >
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1" }}>Приглашенные гости</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1" }}>Фотозона</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1" }}>Ярмарка</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1" }}>Аллея авторов</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1" }}>Стенд фестиваля</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1" }}>Лотерея и мерч фестиваля</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1" }}>Файтинги и Just Dance</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1" }}>Игротека</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1" }}>Стенды партнеров</div>
          </div>
        </div>
        <div className="container-v center">
          <div
            className="header"
            style={{ textAlign: "center", fontSize: "26px" }}
          >
            Генеральный партнер фестиваля
          </div>
          <a href="https://vk.com/gensei_shop" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/gensei.png"
              alt="Gensei"
              style={{ maxWidth: "150px" }}
            />
          </a>
          <div
            className="header"
            style={{ textAlign: "center", fontSize: "26px" }}
          >
            Наши спонсоры и партнеры
          </div>
          <div
            className="container center wrap"
            style={{
              gap: "10px",
            }}
          >
            {" "}
            <a
              href="https://vk.com/rusanimefest"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/rusanimefest_v2.png"
                alt="RusAnimeFest"
                style={{ maxWidth: "100px" }}
              />
            </a>
            <a
              href="https://vk.com/radioactive_daddy"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/logo2_tr-min.png"
                alt="radioactive_daddy"
                style={{ maxWidth: "70px" }}
              />
            </a>
            <a
              href="https://vk.com/hobbygames_ufa"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/HG_color_2.png"
                alt="Hobby Games"
                style={{ maxWidth: "70px" }}
              />
            </a>
            <a href="https://vk.com/sumiko_tv" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/SUMIKO.png"
                alt="Sumiko"
                style={{ maxWidth: "70px" }}
              />
            </a>
            <a
              href="https://likee.video/@NYAF_UFA"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/Likee.png"
                alt="Likee"
                style={{ maxWidth: "70px" }}
              />
            </a>
            <a
              href="https://vk.com/voice_haus"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/VoiceHaus.png"
                alt="Voice Haus"
                style={{ maxWidth: "70px" }}
              />
            </a>
            <a
              href="https://vk.com/teguri.wear"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/teguri.png"
                alt="Teguri"
                style={{ maxWidth: "70px" }}
              />
            </a>
            <a
              href="https://vk.com/materialsforart"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/ics.png"
                alt="ICS"
                style={{ maxWidth: "100px" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAllBlocks();
  }, []);

  return (
    <div
      className="container-v"
      style={{ width: "100%", height: "100%", marginTop: "60px" }}
    >
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <div className="container">
        {getScheduleMenu("schedule", "schedule.svg")}
        {getScheduleMenu("likes", "heart.svg")}
        {getScheduleMenu("map", "fest-map.svg")}
        {getScheduleMenu("info", "info-icon.svg")}
      </div>
      {currentPage === "schedule" && getSchedule()}
      {currentPage === "likes" && getLikes()}
      {currentPage === "map" && getMap()}
      {currentPage === "info" && getInfo()}
    </div>
  );
}

export default SchedulePage;
