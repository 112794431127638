import React from "react";
import { useCountdown } from "./Countdown/useCountdown";
import Countdown from "./Countdown/Countdown";
import "./ApplicationsCountdown.css";
import { getDate } from "../helpers";

const ApplicationsCountdown = ({
  title,
  targetDate,
  style,
}: {
  title: string;
  targetDate: string;
  style?: React.CSSProperties;
}) => {
  const [days, hours, minutes, seconds] = useCountdown(getDate(targetDate));

  if (days + hours + minutes + seconds <= 0) {
    return <div></div>;
  } else {
    return (
      <div className="blue-bubble" style={style}>
        <Countdown
          title={title}
          days={days}
          hours={hours}
          small={true}
          className="applications-countdown"
        />
      </div>
    );
  }
};

export default ApplicationsCountdown;
