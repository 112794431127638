import React from "react";

function NotAuthorizedPage() {
  return (
    <div className="hall longread">
      <div className="hall-section container-v center">
        <div className="content">
          <div className="longread-content container-v">
            <h2>Недостаточно прав для просмотра этой страницы!</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotAuthorizedPage;
