import { jwtDecode } from "jwt-decode";

export const isAccessTokenValid = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    logoutUser();
    return false; // No access token in sessionStorage
  }

  const token = jwtDecode(accessToken); // Decode the JWT payload
  if (!token || Date.now() >= (token.exp ?? 0) * 1000) {
    logoutUser();
    return false;
  }
  return true;
};

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const getUserPhotoLink = () => {
  return localStorage.getItem("photo");
};

export const isAdmin = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    return false; // No access token in sessionStorage
  }

  const token = JSON.parse(atob(accessToken.split(".")[1])); // Decode the JWT payload
  if (token && token.isAdmin === true) {
    return true;
  }
  return false;
};

export const logoutUser = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("photo");
  localStorage.removeItem("uid-vk");
};

export const fetchWithAuth = (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  body?: any
) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    });
  } else {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }
};

export const uploadFileWithAuth = (url: string, file: Blob) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetch(url, {
    method: "POST", // You can choose the appropriate HTTP method for your use case
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: formData,
  });
};
