import React from "react";

import "./Tickets.css";
import TicketsWidget from "../components/TicketsWidget";
import OfflineShopsWidget from "../components/OfflineShopsWidget";

function Tickets() {
  return (
    <div className="hall longread">
      <div className="hall-section container-v center">
        <div className="content tickets-content">
          <div
            className="container-v center"
            // dangerouslySetInnerHTML={{ __html: ticketsData }}
          >
            <div className="header">БИЛЕТЫ</div>
            <h2 className="text" style={{ marginBottom: "30px" }}>
              Вы можете приобрести билеты на фестиваль в магазинах наших
              партнеров:{" "}
            </h2>
            <OfflineShopsWidget />
            <h2 className="text" style={{ marginTop: "30px" }}>
              или купить онлайн:
            </h2>
            <TicketsWidget />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tickets;
