import { Field } from "../admin/types";
import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

// Helper function to handle fetch errors
const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function getFields(): Promise<Field[]> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/fields`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Field[];
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function postField(body: any): Promise<Field> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/fields`, "POST", body);

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Field;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function putField(id: string, body: any): Promise<Field> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/fields/${id}`,
      "PUT",
      body
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Field;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}

export async function deleteField(id: string): Promise<boolean> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/fields/${id}`, "DELETE");

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }

    return Promise.resolve(true);
  } catch (error) {
    handleFetchError(error);
    return Promise.reject();
  }
}
