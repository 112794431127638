import React from "react";
import "./InfoSection.css";
import BubbleWithPopUp from "../BubbleWithPopUp";
import TicketsWidget from "../TicketsWidget";
import OfflineShopsWidget from "../OfflineShopsWidget";

export type Bubble = {
  id: number;
  label: string;
  image: string;
  text: string;
  buttonText?: string;
  buttonLink?: string;
  socials?: { platform: string; link: string }[];
  color: string;
};

const awaitsBubbles: Bubble[] = [
  {
    id: 1,
    label: "КОНКУРС КОСПЛЕЯ",
    image: "cosplay.png",
    text: `<p>Косплей (от англ. costume play — «костюмированная
      игра») — перевоплощение в образ персонажа 
      из аниме, игр, кино, сералов и других источников,
      а так же изготовление для этих образов костюма 
      и атрибутики.</p><p>
      Конкурс косплея — традиционная часть фестивалей
      современной культуры, где участники со всей России
      соревнуются в том, у кого лучше удалось изготовить
      костюм и передать характер персонажа на сцене.</p><p>
      Победители во всех номинациях получат ценные
      призы от нас, наших друзей и партнёров!</p>`,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "black",
  },
  {
    id: 2,
    label: "COVER DANCE",
    image: "dance.png",
    text: `<p>K-pop — музыкальный жанр, возникший в Южной Корее
     и вобравший в себя элементы западного электропопа, хип-хопа, 
     танцевальной музыки и современного ритм-н-блюза. 
     Появившись как музыкальный жанр, <nobr>K-pop</nobr> превратился в масштабную 
     субкультуру с миллионами поклонников во всём мире и наш 
     фестиваль не остался в стороне!</p><p>Cover dance - 
     популярное танцевальное направление, которому традиционно
      посвящена часть нашего фестиваля. Участники со всей России 
      соревнуются в сценической хореографии, постановке номера 
      и качестве исполнения.</p><p>Победители номинации получат 
      памятные призы от нашего фестиваля и партнеров!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "black",
  },
  {
    id: 3,
    label: "КОНКУРС ВОКАЛА",
    image: "vocal.png",
    text: `<p>Конкурс вокала – неотъемлемая часть фестиваля NYAF. 
    Участники  номинации показывают живое вокальное выступление на 
    сцене, как правило, сопровождающееся сценической постановкой. </p> 

    <p>Здесь можно услышать аниме-опенинги, саундтреки из любимых
     видеоигр и фильмов, сцены из мьюзиклов, каверы на песни известных 
     <nobr>k-pop</nobr> исполнителей и многое другое.</p> 
    
    <p>Соревнуясь в качестве исполнения, постановке номера и 
    вокальной технике, участники данной номинации могут 
    выиграть ценные призы от нашего фестиваля и партнеров!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "black",
  },
  {
    id: 4,
    label: "ФАНАТСКИЕ СТЕНДЫ",
    image: "stands.png",
    text: `<p>Фанатские стенды представляют собой зону с 
    декорациями по определенному фандому: аниме, видеоигре, 
    фильму или книге, комиксу, анимации или по определенной тематике. </p> 

    <p>На протяжении всей работы фанатского 
    стенда в зоне находятся участники в образах и проводят 
    различного рода активности и конкурсы для всех
     участников и гостей фестиваля.</p> 
    
    <p>Интересно, какие стенды будут представлены 
    на NYAF? Следи за новостями в нашей группе и узнай первым!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "black",
  },
  {
    id: 5,
    label: "ЯРМАРКА",
    image: "fair.png",
    text: `<p>Во время фестиваля вас будет ждать разнообразная ярмарка, 
    где каждый желающий сможет найти фандомную атрибутику на любой вкус. </p> 

    <p>В холле будут представлены <nobr>geek-магазины</nobr> как из Уфы, 
    так и из других городов. Наши друзья представят огромный выбор 
    товаров от маленьких значков и наклеек до коллекционных фигурок и ростовых подушек. </p> 
    
    <p>Гости фестиваля смогут найти уникальную аниме, geek, 
    <nobr>k-pop</nobr> атрибутику и не только. Также на ярмарке вы сможете приобрести
     на память сувенирную продукцию от нашего фестиваля!</p> 
    `,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "black",
  },
  {
    id: 6,
    label: "АЛЛЕЯ АВТОРОВ",
    image: "authors.png",
    text: `<p>Аллея авторов - место, где художники и 
    мастера могут представить своё творчество. 
    Здесь вы можете приобрести уникальные авторские 
    товары, познакомиться с творческими людьми и даже 
    заказать свой портрет в авторском стиле!</p>
    <p>Мы всегда рады новым авторам! Если вы создаёте 
    собственные иллюстрации, комиксы или уникальные 
    handmade-товары и хотите поделиться своим 
    творчеством с другими — добро пожаловать к нам!</p>`,
    buttonText: "ПОДАТЬ ЗАЯВКУ",
    color: "black",
  },
  {
    id: 7,
    label: "ИГРОВАЯ ЗОНА",
    image: "games.png",
    text: `<p>Игровая зона - место на фестивале, где вы сможете отдохнуть от толпы. 
    Здесь вы сможете собраться с друзьями или найти новую компанию 
    для партии в настольную или видеоигру. </p>

    <p>Вы можете сможете воспользоваться открытой игротекой 
    настольных игр от наших партнеров. Это отличная возможность 
    весело провести время в перерыве между выступлениями участников
     и познакомить близких с культурой настольных игр.</p>
     <p>Также в игровой зоне Вас будут ждать <nobr>Free-to-play</nobr>
      ритм-игры, где Вы сможете проверить свою реакцию и 
      чувство ритма и файтинги для встречи «один на один» 
      против компьютерного игрока или вашего друга.</p>
    `,
    color: "black",
  },
  {
    id: 8,
    label: "КОНКУРСЫ И КВЕСТЫ",
    image: "quests.png",
    text: `<p>На фестивале NYAF каждый гость сможет 
    принять участие в конкурсах и квестах.</p>

    <p>Следите за нашим маскотом Аюми-тян! 
    Она приготовила много интересного и с 
    радостью наградит активных зрителей!</p>
    
    <p>На NYAF также можно принять участие в 
    интерактивном квесте - для этого нужно лишь 
    проявить смекалку и следить за новостями в нашей группе!
     Принять участие может каждый гость в день проведения 
     фестиваля, один или в компании друзей. Во время прохождения 
     квеста Вы сможете поближе познакомиться с фестивалем, 
     узнать интересные факты и, конечно же, забрать с собой памятные сувениры! </p>
    `,
    color: "black",
  },
];

const InfoSection = () => {
  const onMouseEnterBusStop = (name: string) => {
    document.getElementById(name)?.classList.add("bus-stop-name-show");
  };

  const onMouseLeaveBusStop = (name: string) => {
    document.getElementById(name)?.classList.remove("bus-stop-name-show");
  };

  return (
    <div className="content-section white-section container-v center">
      <div
        className="content"
        style={{ zIndex: "1", backgroundColor: "white" }}
      >
        <div id="what" className="container-v center">
          <div
            className="text header-text mobile"
            style={{ marginBottom: "34px" }}
          >
            САМЫЙ ТЁПЛЫЙ ЗИМНИЙ ФЕСТИВАЛЬ
          </div>
          <div className="container what">
            <div className="image-container">
              <img
                className="image"
                style={{ width: "100%", height: "auto", display: "block" }}
                src="What.png"
                alt="Festival"
              />
            </div>
            <div id="what-description" className="container-v what-description">
              <div className="text header-text desktop">
                САМЫЙ ТЁПЛЫЙ ЗИМНИЙ ФЕСТИВАЛЬ
              </div>
              <div className="text body-text">
                {`NYAF — один из старейших и крупнейших фестивалей
              популярной молодёжной культуры города Уфа
              c неповторимой атмосферой тепла и уюта,
              ежегодно объединяющий на своей площадке
              фанатов аниме, видеоигр, комиксов, кино и сериалов, 
              настолок, k-pop и много чего еще!`}
              </div>
              <a className="text more-text" href="/about">
                Узнать больше
              </a>
            </div>
          </div>
        </div>
        <div
          id="where"
          className="container-v center"
          style={{ marginTop: "115px" }}
        >
          <div
            className="text header-text mobile"
            style={{ marginBottom: "34px" }}
          >
            МЕСТО ПРОВЕДЕНИЯ
          </div>
          <div className="container where">
            <div
              id="where-description"
              className="container-v where-description"
              style={{
                margin: "30px 0px",
              }}
            >
              <div className="text header-text desktop">МЕСТО ПРОВЕДЕНИЯ</div>
              <div
                style={{
                  marginTop: "40px",
                }}
                className="container body-text"
              >
                <img
                  style={{ width: "30px", marginRight: "12px" }}
                  src="pin.svg"
                  alt="Pin"
                />
                <span>
                  <b>ДК «Орджоникидзе»</b>
                  <br />
                  Первомайская ул., 14
                </span>
              </div>
              <div className="container maps wrap desktop">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://yandex.ru/maps/172/ufa/house/pervomayskaya_ulitsa_14/YU8YdgFoSkYHQFtufXRweHhkYg==/?ll=56.068331%2C54.819477&z=16.74"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "145px" }}
                    src="yandex-maps.png"
                    alt="Yandex Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://2gis.ru/ufa/firm/70000001053152040?m=56.067977%2C54.819463%2F16"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "115px" }}
                    src="2gis-maps.png"
                    alt="2GIS Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/%D0%A3%D1%84%D0%B8%D0%BC%D1%81%D0%BA%D0%B8%D0%B9+%D0%B3%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B9+%D0%BD%D0%B5%D1%84%D1%82%D1%8F%D0%BD%D0%BE%D0%B9+%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9+%D1%83%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82/@54.8189089,56.0667345,17.52z/data=!4m15!1m8!3m7!1s0x43d9208bea4fba89:0xba34be4fd6a74a5!2z0J_QtdGA0LLQvtC80LDQudGB0LrQsNGPINGD0LsuLCAxNCwg0KPRhNCwLCDQoNC10YHQvy4g0JHQsNGI0LrQvtGA0YLQvtGB0YLQsNC9LCDQoNC-0YHRgdC40Y8sIDQ1MjE1MQ!3b1!8m2!3d54.5492947!4d55.747959!16s%2Fg%2F11lkd1bb4q!3m5!1s0x43d937e0f227c583:0x31b3dd7600febccc!8m2!3d54.8193271!4d56.0686152!16s%2Fm%2F09rx5hr?entry=ttu"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "236px" }}
                    src="google-maps.png"
                    alt="Google Maps"
                  />
                </a>
              </div>
              <div className="container maps mobile">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://yandex.ru/maps/172/ufa/house/pervomayskaya_ulitsa_14/YU8YdgFoSkYHQFtufXRweHhkYg==/?ll=56.068331%2C54.819477&z=16.74"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "50px" }}
                    src="yandex-maps-small.png"
                    alt="Yandex Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://2gis.ru/ufa/firm/70000001053152040?m=56.067977%2C54.819463%2F16"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "65px" }}
                    src="2gis-maps-small.png"
                    alt="2GIS Maps"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/%D0%A3%D1%84%D0%B8%D0%BC%D1%81%D0%BA%D0%B8%D0%B9+%D0%B3%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B9+%D0%BD%D0%B5%D1%84%D1%82%D1%8F%D0%BD%D0%BE%D0%B9+%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9+%D1%83%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82/@54.8189089,56.0667345,17.52z/data=!4m15!1m8!3m7!1s0x43d9208bea4fba89:0xba34be4fd6a74a5!2z0J_QtdGA0LLQvtC80LDQudGB0LrQsNGPINGD0LsuLCAxNCwg0KPRhNCwLCDQoNC10YHQvy4g0JHQsNGI0LrQvtGA0YLQvtGB0YLQsNC9LCDQoNC-0YHRgdC40Y8sIDQ1MjE1MQ!3b1!8m2!3d54.5492947!4d55.747959!16s%2Fg%2F11lkd1bb4q!3m5!1s0x43d937e0f227c583:0x31b3dd7600febccc!8m2!3d54.8193271!4d56.0686152!16s%2Fm%2F09rx5hr?entry=ttu"
                >
                  <img
                    className="map-link"
                    style={{ maxWidth: "55px" }}
                    src="google-maps-small.png"
                    alt="Google Maps"
                  />
                </a>
              </div>
              <a className="text more-text" href="/what-where-when">
                Узнать больше
              </a>
            </div>

            <div className="image-container">
              <img className="image" src="map.png" alt="Map" />
              <div
                id="bus-stop-1"
                className="bus-stop"
                style={{ left: "32.66%", top: "65.52%" }}
              >
                <div
                  id="bus-stop-name-1"
                  className="bus-stop-name"
                  style={{ marginLeft: "-16px" }}
                >
                  Площадь имени Серго Орджоникидзе
                </div>
                <img
                  src="busstop.svg"
                  alt="Bus stop"
                  width="28px"
                  onMouseEnter={() => onMouseEnterBusStop("bus-stop-name-1")}
                  onMouseLeave={() => onMouseLeaveBusStop("bus-stop-name-1")}
                />
              </div>
              <div
                id="bus-stop-2"
                className="bus-stop"
                style={{ left: "63%", top: "52.4%", flexDirection: "row" }}
              >
                <div
                  id="bus-stop-name-2"
                  className="bus-stop-name"
                  style={{ marginLeft: "-216px", padding: "2px 15px 0px 5px" }}
                >
                  Площадь имени Серго Орджоникидзе
                </div>
                <img
                  src="busstop.svg"
                  alt="Bus stop"
                  width="28px"
                  onMouseEnter={() => onMouseEnterBusStop("bus-stop-name-2")}
                  onMouseLeave={() => onMouseLeaveBusStop("bus-stop-name-2")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-v center tickets">
          <div className="text header-text" style={{ marginBottom: "20px" }}>
            КУПИТЬ БИЛЕТЫ
          </div>
          <TicketsWidget />
          <div
            className="text"
            style={{
              margin: "20px 0px",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            или офлайн в магазинах наших партнёров:
          </div>
          <div style={{ maxWidth: "700px" }}>
            <OfflineShopsWidget />
          </div>
        </div>
        <div className="container-v center awaits">
          <div className="text header-text" style={{ color: "#000000" }}>
            ВАС ЖДЁТ
          </div>
          <div
            className="container wrap center"
            style={{ marginTop: "20px", alignItems: "baseline" }}
          >
            {awaitsBubbles.map((bubble) => (
              <BubbleWithPopUp bubble={bubble} key={bubble.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
