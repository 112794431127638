import {
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Snackbar,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Block, MyApplication } from "../types";
import { getBlocks } from "../../services/scheduleService";
import { getApplications } from "../../services/applicationsService";

function ScheduleExports() {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [appData, setAppData] = useState<MyApplication[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<
    "common" | "stage" | "light" | "music"
  >("common");

  const getTranscriptionTemplates = (nominationId: string) => {
    switch (nominationId) {
      // одиночка
      case "7a251675-900a-46a8-82b5-6d4357abe809":
        return "{nickname_tr} ({city}) - {char_name_tr} ({fandom_tr})";
      // anticosplay
      case "008f3b3f-4f6f-4168-87fc-82116482df25":
        return "{nick_or_team_tr} ({city}) — {char_name_tr} ({fandom_tr})";
      // group cosplay
      case "4e3d1a9a-da44-42d5-b746-ff3faf0456e2":
        return "{team_name_tr} ({city}) — {char_name_tr} ({fandom_tr})";
      // vocal
      case "a29d8870-d18a-4f7e-aded-e53df37eecea":
        return "{nick_or_team_tr} ({city}) — {dance_name_tr} ({fandom_tr})";
      // cover dance
      case "eb88582e-0d36-40f2-87c0-4ff9deb24dfc":
        return "{team_name_tr} ({city}) — {dance_name_tr} ({fandom_tr})";
      // pair cosplay
      case "fae95dd5-9c0d-4ca3-91cb-792ee4b52237":
        return "{team_name_tr} ({city}) — {char_name_tr} ({fandom_tr})";
      default:
        return "";
    }
  };

  const replacePlaceholders = (
    template: string,
    applicationData: any[]
  ): string => {
    try {
      const placeholderRegex = /{(\w+)}/g;

      // Replace placeholders in the template
      const replacedTemplate = template.replace(
        placeholderRegex,
        (match, placeholder) => {
          const field = applicationData.find(
            (a) => a.field.code === placeholder
          ).field;
          const data = applicationData.find(
            (item) => item.fieldId === field.fieldId
          );
          return data ? data.value : match; // Replace with data.value or keep the original placeholder
        }
      );

      return replacedTemplate;
    } catch {
      return "";
    }
  };

  const getAllBlocks = async () => {
    const blocksData = await getBlocks();
    let time = new Date(2023, 1, 1, 12, 0, 0, 0).getTime();
    const orderedBlocks = blocksData
      .sort((a, b) => a.order - b.order)
      .map((b) => {
        const startTime = new Date(time);
        let newBlock = {
          ...b,
          duration: b.durationInSeconds / 60,
          startTime: `${startTime.getHours()}:${
            startTime.getMinutes() < 10
              ? "0" + startTime.getMinutes()
              : startTime.getMinutes()
          }`,
        };
        time += b.durationInSeconds * 1000;
        return newBlock;
      });
    setBlocks(orderedBlocks);
  };

  const getAppData = async () => {
    const applicationsData = await getApplications();
    setAppData(applicationsData);
  };

  useEffect(() => {
    getAllBlocks();
    getAppData();
  }, []);

  const getCommonSchedule = () => {
    let itemIndex = 0;
    return blocks.map((b, index) => (
      <div key={b.blockId}>
        <div className="text">{`${b.startTime} ${b.name} ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? blocks
                .filter((bl) => bl.nominationId === b.nominationId)
                .findIndex((bl) => bl.blockId === b.blockId) + 1
            : ""
        }  ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? "блок"
            : ""
        }`}</div>
        <div className="container-v" style={{ width: "100%" }}>
          {b.scheduleItems.map((i) => (
            <div style={{ color: "white" }}>{`${(itemIndex = itemIndex + 1)}. ${
              i.name
            }`}</div>
          ))}
        </div>{" "}
      </div>
    ));
  };

  const getStageSchedule = () => {
    let itemIndex = 0;
    return blocks.map((b, index) => (
      <div key={b.blockId}>
        <div className="text">{`${b.startTime} ${b.name} ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? blocks
                .filter((bl) => bl.nominationId === b.nominationId)
                .findIndex((bl) => bl.blockId === b.blockId) + 1
            : ""
        }  ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? "блок"
            : ""
        }`}</div>
        <TableContainer component={Paper}>
          <Table
            sx={{ height: "80%", overflow: "auto" }}
            aria-label="simple table"
          >
            <TableBody>
              {b.scheduleItems.map((i) => (
                <TableRow
                  key={i.scheduleItemId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {
                      appData
                        .find((a) => a.applicationId === i.applicationId)
                        ?.applicationData?.find(
                          (a) => a.field.code === "defile-start"
                        )?.value
                    }
                  </TableCell>
                  <TableCell align="left">
                    <div className="container-v">
                      <div style={{ color: "white" }}>{`${(itemIndex =
                        itemIndex + 1)}. ${i.name}`}</div>
                      <div
                        style={{ color: "white" }}
                      >{`${itemIndex}. ${replacePlaceholders(
                        getTranscriptionTemplates(b.nominationId),
                        appData.find((a) => a.applicationId === i.applicationId)
                          ?.applicationData ?? []
                      )}`}</div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ));
  };

  const getLightSchedule = () => {
    let itemIndex = 0;
    return blocks.map((b, index) => (
      <div key={b.blockId}>
        <div className="text">{`${b.startTime} ${b.name} ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? blocks
                .filter((bl) => bl.nominationId === b.nominationId)
                .findIndex((bl) => bl.blockId === b.blockId) + 1
            : ""
        }  ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? "блок"
            : ""
        }`}</div>
        <div className="container-v" style={{ width: "100%" }}>
          {b.scheduleItems.map((i) => (
            <div className="container-v">
              <div style={{ color: "white" }}>{`${(itemIndex =
                itemIndex + 1)}. ${i.name}`}</div>
              <div>
                {
                  appData
                    .find((a) => a.applicationId === i.applicationId)
                    ?.applicationData?.find((d) => d.field.code === "light")
                    ?.value
                }
              </div>
            </div>
          ))}
        </div>{" "}
      </div>
    ));
  };

  const getMusicSchedule = () => {
    let itemIndex = 0;
    return blocks.map((b, index) => (
      <div key={b.blockId}>
        <div className="text">{`${b.startTime} ${b.name} ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? blocks
                .filter((bl) => bl.nominationId === b.nominationId)
                .findIndex((bl) => bl.blockId === b.blockId) + 1
            : ""
        }  ${
          b.nominationId !== "bd048010-bb0d-45c7-9734-763d077f3b91"
            ? "блок"
            : ""
        }`}</div>
        <div className="container-v" style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ height: "80%", overflow: "auto" }}
              aria-label="simple table"
            >
              <TableBody>
                {b.scheduleItems.map((i) => (
                  <TableRow
                    key={i.scheduleItemId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {appData
                        .find((a) => a.applicationId === i.applicationId)
                        ?.applicationData?.find((d) => d.field.code === "video")
                        ?.value
                        ? "ВИДЕО"
                        : "АУДИО"}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ color: "white" }}>{`${(itemIndex =
                        itemIndex + 1)}`}</div>
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ color: "white" }}>{`${i.name}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {
                        appData
                          .find((a) => a.applicationId === i.applicationId)
                          ?.applicationData?.find(
                            (d) => d.field.code === "extra_info"
                          )?.value
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {
                        appData
                          .find((a) => a.applicationId === i.applicationId)
                          ?.applicationData?.find(
                            (d) => d.field.code === "video"
                          )?.value
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>{" "}
      </div>
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div
        style={{
          width: "1000px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading && (
          <div className="loading-overlay" style={{ position: "absolute" }}>
            <div className="spinner"></div>
          </div>
        )}
        <div className="text">Расписание</div>
        <Button onClick={() => setSchedule("common")}>Обычное</Button>
        <Button onClick={() => setSchedule("stage")}>Для ведущих</Button>
        <Button onClick={() => setSchedule("light")}>Для световика</Button>
        <Button onClick={() => setSchedule("music")}>Для звуковика</Button>
        <div className="container-v" style={{ overflowY: "scroll" }}>
          {schedule === "common" && getCommonSchedule()}
          {schedule === "stage" && getStageSchedule()}
          {schedule === "light" && getLightSchedule()}
          {schedule === "music" && getMusicSchedule()}
        </div>
      </div>
    </div>
  );
}

export default ScheduleExports;
