import React, { useEffect, useState } from "react";
import { NominationWithApplications } from "../admin/types";
import { getNominationsWithApplicationsPublic } from "../services/nominationsService";
import { getSetting } from "../services/settingsService";

function AllApplications() {
  const [applications, setApplications] =
    useState<NominationWithApplications[]>();

  const [showAccepted, setShowAccepted] = useState<boolean>(false);

  const getConfigValue = async (key: string) => {
    const value = await getSetting(key);
    return value;
  };

  const getShowAccepted = async () => {
    const value = await getConfigValue("showAccepted");
    setShowAccepted(value as unknown as boolean);
  };

  const getAllApplications = async () => {
    const applicationsData = await getNominationsWithApplicationsPublic();
    setApplications(applicationsData);
  };

  useEffect(() => {
    getAllApplications();
    getShowAccepted();
    // eslint-disable-next-line
  }, []);

  const getColor = (state: any) => {
    const appState = showAccepted
      ? state
      : state === 4 || state === 3
      ? 2
      : state;

    if (appState === 4) {
      return "#01e0c4";
    }

    if (appState === 3) {
      return "#a0a0a0";
    }
    return "#000";
  };

  const getStatus = (state: any) => {
    const appState = showAccepted
      ? state
      : state === 4 || state === 3
      ? 2
      : state;

    return appState;
  };

  return (
    <div className="contest longread">
      {applications ? (
        <div className="content-section container-v center">
          <div className="content">
            <div className="longread-content container-v">
              <h1>Пришедшие заявки</h1>
              <div className="container-v">
                {applications
                  .filter((a) => a.name !== "Служебная")
                  .map((n) => (
                    <div key={n.nominationId} className="container-v">
                      <h2>{n.name}</h2>
                      {n.subNominations.map((s) => (
                        <div
                          key={s.subNominationId}
                          className="container-v"
                          style={{ marginLeft: "15px" }}
                        >
                          {n.subNominations.length > 1 &&
                            s.applications.length > 0 && <h4>{s.name}</h4>}
                          {s.applications.length > 0 && (
                            <div className="regular-text">
                              {s.applications.map((a) => (
                                <div
                                  style={{ color: `${getColor(a.status)}` }}
                                  className={`${
                                    getStatus(a.status) === 4 ? "text" : ""
                                  }`}
                                >
                                  {a.fullName}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllApplications;
