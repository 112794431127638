// RegistrationService.js
import { BASE_URL } from "../config";
import { UserProfile } from "../types/UserProfile";
import RegisterDto from "../types/register.dto";
import { getAccessToken, isAccessTokenValid } from "../utils/authUtils";

export const registerUser = async (registerDto: RegisterDto) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerDto),
    });

    if (!response.ok) {
      throw new Error(`Registration failed with status ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Registration failed:", error);
    throw error;
  }
};

export const confirmEmail = async (code: string) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/confirm-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ confirmationCode: code }),
    });

    if (!response.ok) {
      throw new Error(
        `Email confirmation failed with status ${response.status}`
      );
    }

    return;
  } catch (error) {
    console.error("Email confirmation failed:", error);
    throw error;
  }
};

export const getProfile = async (): Promise<UserProfile> => {
  if (isAccessTokenValid()) {
    try {
      const response = await fetch(`${BASE_URL}/auth/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Fetching profile failed with status ${response.status}`
        );
      }

      const data = await response.json();
      return data as UserProfile;
    } catch (error) {
      console.error("Fetching profile failed:", error);
      throw error;
    }
  } else {
    throw new Error(`Unauthorized`);
  }
};
