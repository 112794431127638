import { Container } from "@mui/material";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ResponsiveAppBar from "./AppBar";
import { Outlet } from "react-router-dom";
function Admin() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <Container maxWidth="xl" sx={{ height: "90vh" }}>
        <ResponsiveAppBar />
        <Outlet />
      </Container>
    </ThemeProvider>
  );
}

export default Admin;
