import React from "react";

import "./OfflineShopsWidget.css";

function OfflineShopsWidget() {
  const getPartnerCard = (
    name: string,
    address: string,
    addressLink: string
  ) => {
    return (
      <div className="container partner-card">
        <a
          href={addressLink}
          target="_blank"
          rel="noreferrer"
          className="partner-link"
        >
          <img
            style={{ width: "30px", marginRight: "12px" }}
            src="pin.svg"
            alt="Pin"
          />
          <div className="container-v">
            <div className="text partner-name">{name}</div>
            <div className="partner-address">{address}</div>
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className="container-v center">
      <div className="container center partners-links">
        {getPartnerCard(
          "Gensei Shop",
          "Айская ул., 84",
          "https://go.2gis.com/65d26"
        )}
        {getPartnerCard(
          "Gensei Shop",
          "Ульяновых ул., 39",
          "https://go.2gis.com/hsyqv"
        )}
        {getPartnerCard(
          "Geek Shop",
          "Ленина ул., 9/11",
          "https://go.2gis.com/44jna"
        )}
        {getPartnerCard(
          "Oresama",
          "Проспект октября, 46",
          "https://go.2gis.com/hxcqv"
        )}
        {getPartnerCard(
          "Oresama",
          "Маршала Жукова ул, 10",
          "https://go.2gis.com/y72gd"
        )}
      </div>
      <div className="partner-subtitle">
        <strong>ВНИМАНИЕ:</strong> в магазинах партнеров доступны к покупке{" "}
        <strong>только</strong> билеты гостя!
      </div>
    </div>
  );
}

export default OfflineShopsWidget;
