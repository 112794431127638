import React, { ReactChild, useEffect, useState } from "react";
import { Photocosplay } from "../admin/types";
import "./PhotocosplayPreviewWithPopUp.css";
import ReactStars from "react-stars";
import { setPhotocosplayRating } from "../services/applicationsService";
import { isAccessTokenValid } from "../utils/authUtils";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FILES_URL } from "../config";

const PhotocosplayPreviewWithPopUp = ({
  photocosplay,
  ratingChanged,
}: {
  photocosplay: Photocosplay;
  ratingChanged: (applicationId: string, rating: number) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeRating = async (newRating: number) => {
    ratingChanged(photocosplay.applicationId, newRating);
  };
  const isDesktop = window.screen.width > 1450;
  const isMobile = window.screen.width < 750;
  const onOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Check if the clicked element is the overlay, not the modal content
    if (
      event.target instanceof HTMLDivElement &&
      event.target.id === "modal_overlay"
    ) {
      // Close the modal
      setIsModalOpen(false);
    }
  };

  const [photos, setPhotos] = useState<string[]>([]);
  useEffect(() => {
    const ph = [];
    if (photocosplay.photo1Url && photocosplay.photo1Url !== "") {
      ph.push(photocosplay.photo1Url);
    }
    if (photocosplay.photo2Url && photocosplay.photo2Url !== "") {
      ph.push(photocosplay.photo2Url);
    }
    if (photocosplay.photo3Url && photocosplay.photo3Url !== "") {
      ph.push(photocosplay.photo3Url);
    }
    setPhotos(ph);
  }, [photocosplay]);

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        <img
          className="photocosplay-thumbnail"
          src={`${FILES_URL}/${photocosplay.photo1Url}`}
          alt={photocosplay.cosplayer}
        />
      </div>
      {isModalOpen && (
        <div
          id="modal_overlay"
          className="modal-overlay"
          onClick={(event) => onOverlayClick(event)}
        >
          <div id="modal" className="photocosplay-modal">
            <div className="container-v" style={{ height: "100%" }}>
              <Carousel showThumbs={false} swipeable={true}>
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "unset",
                  }}
                  src={`${FILES_URL}/${photocosplay.imageUrl}`}
                  alt={photocosplay.character}
                />
                {
                  photos.map((p) => {
                    return (
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          width: "unset",
                        }}
                        src={`${FILES_URL}/${p}`}
                        alt={photocosplay.character}
                      />
                    );
                  }) as unknown as ReactChild
                }
              </Carousel>

              <div
                className="container-v photocosplay-rating"
                style={{ overflowY: "auto" }}
              >
                <div
                  className="regular-text photocosplay-text"
                  style={{ margin: "25px 0px 2px 0px" }}
                >
                  <strong>Персонаж: </strong>
                  {photocosplay.character}
                </div>
                <div
                  className="regular-text photocosplay-text"
                  style={{ margin: "2px 0px" }}
                >
                  <strong>Фандом: </strong>
                  {photocosplay.fandom}
                </div>
                <div
                  className="regular-text photocosplay-text"
                  style={{ margin: "2px 0px" }}
                >
                  <strong>Косплеер: </strong>
                  {photocosplay.cosplayer}
                </div>
                <div
                  className="regular-text photocosplay-text"
                  style={{ margin: "2px 0px" }}
                >
                  <strong>Фотограф: </strong>
                  {photocosplay.photographer}
                </div>
                {isAccessTokenValid() ? (
                  <>
                    <ReactStars
                      count={10}
                      onChange={changeRating}
                      size={isDesktop ? 50 : isMobile ? 25 : 30}
                      half={false}
                      value={photocosplay.rating}
                      color1={"#D9D9D9"}
                      color2={"#01BBF8"}
                    />
                    {!!photocosplay.rating && (
                      <div className="text photocosplay-text">
                        Ваша оценка: {photocosplay.rating}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="regular-text">
                    Для голосования необходимо авторизоваться на сайте!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotocosplayPreviewWithPopUp;
