import React from "react";
import "./Home.css";
import LogoSection from "../components/Sections/LogoSection";
import InfoSection from "../components/Sections/InfoSection";
import GuestsSection from "../components/Sections/GuestsSection";
import PartnersSection from "../components/Sections/PartnersSection";
import FooterSection from "../components/Sections/FooterSection";

function Home() {
  return (
    <div className="home">
      <LogoSection />
      <InfoSection />
      <GuestsSection />
      <PartnersSection />
    </div>
  );
}

export default Home;
