import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Field,
  MyApplication,
  Nomination,
  NominationWithApplications,
} from "../types";
import { getNominationsWithApplications } from "../../services/nominationsService";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  getApplicationById,
  setAccepted,
  setDeclined,
  setInvalid,
  setPending,
} from "../../services/applicationsService";
import { FILES_URL } from "../../config";
import ReactAudioPlayer from "react-audio-player";
import ImagePreviewWithPopUp from "../../components/ImagePreviewWithPopUp";

function ApplicationsPage() {
  const [applications, setApplications] = useState<
    NominationWithApplications[]
  >([]);

  const [currentApplication, setCurrentApplication] = useState<MyApplication>();
  const [currentNomination, setCurrentNomination] = useState<Nomination>();
  const [adminComment, setAdminComment] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllApplications = async () => {
    const applicationsData = await getNominationsWithApplications();
    setApplications(applicationsData);
    return applicationsData;
  };

  useEffect(() => {
    getAllApplications();
  }, []);

  const openApplication = async (
    allApplications: NominationWithApplications[],
    appl: any,
    n: Nomination
  ) => {
    const updatedApplication = allApplications
      .find((a) => a.nominationId === currentNomination?.nominationId)
      ?.subNominations.find(
        (s) => s.subNominationId === currentApplication?.subNominationId
      )
      ?.applications.find(
        (ap) => ap.applicationId === currentApplication?.applicationId
      );
    const applicationData = await getApplicationById(appl.applicationId);
    const application = {
      ...appl,
      ...updatedApplication,
      applicationData: applicationData,
    };
    setCurrentApplication(undefined);
    setCurrentApplication(application);
    setCurrentNomination(n);
  };

  const justOpenApplication = async (appl: any, n: Nomination) => {
    const applicationData = await getApplicationById(appl.applicationId);
    const application = {
      ...appl,
      applicationData: applicationData,
    };
    setCurrentApplication(application);
    setCurrentNomination(n);
  };

  const setPendingState = async (applicationId: string) => {
    setIsLoading(true);
    await setPending(applicationId);
    const newApplications = await getAllApplications();
    if (currentApplication && currentNomination) {
      await openApplication(
        newApplications,
        currentApplication,
        currentNomination
      );
    }
    setIsLoading(false);
  };

  const setInvalidState = async (applicationId: string, comment: string) => {
    setIsLoading(true);
    await setInvalid(applicationId, comment);
    const newApplications = await getAllApplications();
    if (currentApplication && currentNomination) {
      await openApplication(
        newApplications,
        currentApplication,
        currentNomination
      );
      setAdminComment("");
    }
    setIsLoading(false);
  };

  const setAcceptedState = async (applicationId: string) => {
    setIsLoading(true);
    await setAccepted(applicationId);
    const newApplications = await getAllApplications();
    if (currentApplication && currentNomination) {
      await openApplication(
        newApplications,
        currentApplication,
        currentNomination
      );
    }
    setIsLoading(false);
  };

  const setDeclinedState = async (applicationId: string) => {
    setIsLoading(true);
    await setDeclined(applicationId);
    const newApplications = await getAllApplications();
    if (currentApplication && currentNomination) {
      await openApplication(
        newApplications,
        currentApplication,
        currentNomination
      );
    }
    setIsLoading(false);
  };

  const getStatusBadge = (status: number) => {
    switch (status) {
      case 0:
        return (
          <div
            style={{
              color: "#fff",
              backgroundColor: "#01BBF8",
              borderRadius: "25px",
              padding: "3px 6px",
            }}
            className="badge-text"
          >
            Новая
          </div>
        );
      case 1:
        return (
          <div
            style={{
              color: "#000",
              backgroundColor: "#FEE540",
              borderRadius: "25px",
              padding: "3px 6px",
            }}
            className="badge-text"
          >
            Не хватает данных
          </div>
        );
      case 2:
        return (
          <div
            style={{
              color: "#000",
              backgroundColor: "#E6E8F4",
              borderRadius: "25px",
              padding: "3px 6px",
            }}
            className="badge-text"
          >
            На рассмотрении
          </div>
        );
      case 3:
        return (
          <div
            style={{
              color: "#fff",
              backgroundColor: "#F15BB4",
              borderRadius: "25px",
              padding: "3px 6px",
            }}
            className="badge-text"
          >
            Отклонена
          </div>
        );
      case 4:
        return (
          <div
            style={{
              color: "#000",
              backgroundColor: "#01F4D6",
              borderRadius: "25px",
              padding: "3px 6px",
            }}
            className="badge-text"
          >
            Принята
          </div>
        );
      default:
        break;
    }
  };

  const getCategoryFields = (categoryName: string, categoryCode: string) => {
    var categoryFields = currentApplication?.applicationData?.filter(
      (f) => f.field.category === categoryCode
    );
    return (
      categoryFields?.length !== 0 && (
        <div key={categoryCode} className="container-v" style={{ gap: "15px" }}>
          <h2>{categoryName}</h2>
          {categoryFields
            ?.sort((a, b) => a.field.order - b.field.order)
            .map((f) => {
              return (
                <div
                  key={f.fieldId}
                  className="container field-group-dependancy"
                  style={{
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  {getField(f.field, f.value)}
                </div>
              );
            })}
        </div>
      )
    );
  };

  const getField = (field: Field, value: string): ReactNode => {
    const label = (
      <label className="text container-v" htmlFor={field.code}>
        <div className="container">
          <span>{field.label} </span>
          <span
            style={{
              display: field.required ? "flex" : "none",
              color: "#01bbf8",
              marginLeft: "5px",
            }}
          >
            *
          </span>
        </div>
      </label>
    );
    switch (field.type) {
      case "text":
      case "textarea":
      case "select":
        return (
          <div className={`container-v field`}>
            {label}
            <div>{value}</div>
          </div>
        );

      case "upload-image":
        return (
          <div
            className={`container-v field`}
            style={{ justifyContent: "flex-start" }}
          >
            <label className="text" htmlFor={field.code}>
              {field.label} <br />{" "}
            </label>
            {getImagePreview(value)}
          </div>
        );

      case "upload-music":
        return (
          <div
            className={`container-v field`}
            style={{ justifyContent: "flex-start" }}
          >
            <label className="text" htmlFor={field.code}>
              {field.label} <br />{" "}
            </label>
            {getMusicPreview(value)}
          </div>
        );
      default:
        break;
    }
  };

  const getImagePreview = (value: string) => {
    if (value) {
      return <ImagePreviewWithPopUp url={`${FILES_URL}/${value}`} />;
    }
  };

  const getMusicPreview = (value: string) => {
    if (value) {
      return (
        <div className="container audio-preview">
          <ReactAudioPlayer src={`${FILES_URL}/${value}`} controls />
        </div>
      );
    }
  };

  return (
    <div
      style={{
        marginTop: "70px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div>Фильтры</div>
      <div className="container" style={{ height: "100%" }}>
        <div
          className="container-v"
          style={{ marginLeft: "15px", width: "50%", height: "100%" }}
        >
          {isLoading && (
            <div className="loading-overlay" style={{ position: "absolute" }}>
              <div className="spinner"></div>
            </div>
          )}
          <h1>Пришедшие заявки</h1>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={applications
              .map((n) => {
                return [
                  n.nominationId,
                  ...n.subNominations.map((s) => s.subNominationId),
                ];
              })
              .reduce((a, b) => a.concat(b), [])}
            style={{ height: "100%", overflowY: "scroll" }}
          >
            {applications.map((n) => (
              <TreeItem
                key={n.nominationId}
                nodeId={n.nominationId}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "inherit", flexGrow: 1 }}
                    >
                      {n.name}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                      {n.subNominations
                        .map((s) => s.applications.length)
                        .reduce((accumulator, currentValue) => {
                          return accumulator + currentValue;
                        }, 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="green"
                      style={{ marginLeft: "10px" }}
                    >
                      {n.subNominations
                        .map(
                          (s) =>
                            s.applications.filter((a) => a.state === 4).length
                        )
                        .reduce((accumulator, currentValue) => {
                          return accumulator + currentValue;
                        }, 0)}
                    </Typography>
                  </Box>
                }
              >
                {n.subNominations
                  .sort((a, b) => a.order - b.order)
                  .map((s) => (
                    <TreeItem
                      key={s.subNominationId}
                      nodeId={s.subNominationId}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            p: "10px",
                            pr: "10px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "inherit", flexGrow: 1 }}
                          >
                            {s.name}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            {s.applications.length}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="green"
                            style={{ marginLeft: "10px" }}
                          >
                            {s.applications.filter((a) => a.state === 4).length}
                          </Typography>
                        </Box>
                      }
                    >
                      {s.applications
                        .sort((a, b) => a.fullName.localeCompare(b.fullName))
                        .map((a) => (
                          <TreeItem
                            onClick={() => justOpenApplication(a, n)}
                            key={a.applicationId}
                            nodeId={a.applicationId}
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  p: "10px",
                                  pr: "10px",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "inherit",
                                    flexGrow: 1,
                                    paddingRight: "20px",
                                  }}
                                >
                                  {a.fullName}
                                </Typography>
                                <Typography variant="caption" color="inherit">
                                  {getStatusBadge(a.state)}
                                </Typography>
                              </Box>
                            }
                          />
                        ))}
                    </TreeItem>
                  ))}
              </TreeItem>
            ))}
          </TreeView>
        </div>
        <Divider orientation="vertical" style={{ margin: "0px 20px" }} />
        {currentApplication && (
          <div className="container-v" style={{ height: "100%", width: "50%" }}>
            <div>{currentApplication.fullName}</div>
            {currentApplication.adminNote && (
              <div className="container-v">
                <div className="text">
                  Комментарий администратора: {currentApplication.adminNote}
                </div>
              </div>
            )}
            <Divider />
            <div className="container-v" style={{ overflowY: "scroll" }}>
              <div className="container-v">
                {getCategoryFields(
                  `${
                    currentNomination?.name === "Групповое косплей дефиле" ||
                    currentNomination?.name === "Cover Dance"
                      ? "Данные капитана команды"
                      : "Ваши данные"
                  }`,
                  "personal"
                )}
                {getCategoryFields("Команда", "group")}
                {getCategoryFields("Информация о выступлении", "application")}
                {getCategoryFields("Материалы заявки", "application-media")}
                {getCategoryFields("Дополнительные материалы", "extra")}
              </div>
            </div>
            <Divider />
            <div className="container-v">
              <Button
                variant="contained"
                style={{ marginBottom: "10px" }}
                onClick={() =>
                  setPendingState(currentApplication.applicationId)
                }
              >
                Проверена
              </Button>
              <Divider />
              <TextField
                style={{ flex: 1 }}
                label="Комментарий админа"
                value={adminComment}
                onChange={(event) => {
                  setAdminComment(event.target.value);
                }}
              />
              <Button
                variant="contained"
                onClick={() =>
                  setInvalidState(
                    currentApplication.applicationId,
                    adminComment ?? ""
                  )
                }
              >
                Не хватает материалов
              </Button>
              <div
                className="container"
                style={{ gap: "10px", marginTop: "10px", width: "100%" }}
              >
                <Button
                  style={{ flex: 1 }}
                  variant="contained"
                  color="success"
                  onClick={() =>
                    setAcceptedState(currentApplication.applicationId)
                  }
                >
                  Принять
                </Button>
                <Button
                  style={{ flex: 1 }}
                  variant="contained"
                  color="error"
                  onClick={() =>
                    setDeclinedState(currentApplication.applicationId)
                  }
                >
                  Отклонить
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ApplicationsPage;
