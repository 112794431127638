import React, { useState } from "react";
import "./BubbleWithPopUp.css";
import ModalPopUp from "./ModalPopUp";
import { Bubble } from "./Sections/InfoSection";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const BubbleWithPopUp = ({ bubble }: { bubble: Bubble }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDesktopPopUp = (window.innerWidth > 1450) as Boolean;
  const openMobilePopUp = (window.innerWidth <= 1450) as Boolean;
  return (
    <div>
      <div onClick={() => setIsModalOpen(true)} className="bubble">
        <img src={`bubbles/${bubble.image}`} alt={bubble.label} />
        <div className="text bubble-label" style={{ color: `${bubble.color}` }}>
          {bubble.label}
        </div>
      </div>
      {openDesktopPopUp && (
        <ModalPopUp
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          bubble={bubble}
        />
      )}
      {openMobilePopUp && (
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          scroll={"paper"}
        >
          <DialogTitle id="scroll-dialog-title" style={{ padding: "15px" }}>
            <div className="text popup-label">{bubble.label}</div>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText tabIndex={-1}>
              <div
                className="text popup-text"
                dangerouslySetInnerHTML={{ __html: bubble.text }}
              ></div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <div
              className="text popup-button"
              onClick={() => setIsModalOpen(false)}
            >
              {"ЗАКРЫТЬ"}
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default BubbleWithPopUp;
