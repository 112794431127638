import React from "react";
import { useCountdown } from "./Countdown/useCountdown";
import Countdown from "./Countdown/Countdown";
import { getDate } from "../helpers";

const FestivalCountdown = ({ targetDate }: { targetDate: string }) => {
  const [days, hours, minutes, seconds] = useCountdown(getDate(targetDate));

  if (days + hours + minutes + seconds <= 0) {
    return <div></div>;
  } else {
    return (
      <Countdown
        title={"До фестиваля:"}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default FestivalCountdown;
