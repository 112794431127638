import React from "react";
import "./PartnersSection.css";

const PartnersSection = ({
  heightStyle = "content-section",
}: {
  heightStyle?: string;
}) => {
  return (
    <div className={`${heightStyle} white-section container-v center`}>
      <div className="content container-v center" id="partners-section">
        <div className="text partner-text">ГЕНЕРАЛЬНЫЙ ПАРТНЁР ФЕСТИВАЛЯ</div>
        <a href="https://vk.com/gensei_shop" target="_blank" rel="noreferrer">
          <img
            className="partner-logo"
            src="/partner-images/gensei.png"
            alt="Gensei"
          />
        </a>
        <div className="text partner-text" style={{ marginTop: "100px" }}>
          НАШИ СПОНСОРЫ И ПАРТНЁРЫ
        </div>
        <div
          className="container center wrap"
          style={{
            gap: "45px",
          }}
        >
          {" "}
          <a
            href="https://vk.com/rusanimefest"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/rusanimefest_v2.png"
              alt="RusAnimeFest"
            />
          </a>
          <a
            href="https://vk.com/radioactive_daddy"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/logo2_tr-min.png"
              alt="radioactive_daddy"
            />
          </a>
          <a
            href="https://vk.com/hobbygames_ufa"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/HG_color_2.png"
              alt="Hobby Games"
            />
          </a>
          <a href="https://vk.com/sumiko_tv" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/SUMIKO.png"
              alt="Sumiko"
            />
          </a>
          <a
            href="https://likee.video/@NYAF_UFA"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/Likee.png"
              alt="Likee"
            />
          </a>
          <a href="https://vk.com/voice_haus" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/VoiceHaus.png"
              alt="Voice Haus"
            />
          </a>
          <a href="https://vk.com/teguri.wear" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/teguri.png"
              alt="Teguri"
            />
          </a>
          <a
            href="https://vk.com/materialsforart"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/ics.png"
              alt="ICS"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;
