import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field } from "../types";
import {
  deleteField,
  getFields,
  postField,
  putField,
} from "../../services/fieldsService";

function FieldsPage() {
  const [fields, setFields] = useState<Field[]>([]);
  const [currentField, setCurrentField] = useState<Field>();
  const [dependableFields, setDependableFields] = useState<Field[]>([]);
  const [dependantField, setDependantField] = useState<Field>();

  const addNewField = async (newField: Field) => {
    await postField(newField);
    setCurrentField({
      order: 0,
      label: "",
      subtitle: "",
      type: "text",
      category: "application",
      code: "",
      required: true,
    });
    getAllFields();
  };

  const getAllFields = async () => {
    const allFields = await getFields();
    setFields(allFields);
  };

  const saveField = async (field: Field) => {
    if (field.fieldId) {
      await putField(field.fieldId, field);
      getAllFields();
    }
  };

  const removeField = async (fieldId?: string) => {
    if (fieldId) {
      await deleteField(fieldId);
      getAllFields();
      setCurrentField(undefined);
    }
  };

  useEffect(() => {
    getAllFields();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
        <h1>Поля заявок</h1>
        <Button
          onClick={() => {
            setCurrentField({
              order: 0,
              label: "",
              subtitle: "",
              type: "text",
              category: "application",
              code: "",
              required: true,
            });
          }}
          title="Добавить"
        >
          Добавить поле
        </Button>
        <List style={{ overflowY: "scroll" }}>
          {fields.map((f) => (
            <ListItem key={f.fieldId}>
              <ListItemButton
                onClick={() => {
                  setCurrentField(f);
                  setDependableFields(
                    fields.filter(
                      (field) =>
                        field.dependsOn === null && field.fieldId !== f.fieldId
                    )
                  );
                  setDependantField(
                    fields.find((field) => field.dependsOn === f.fieldId)
                  );
                }}
              >
                <ListItemText primary={f.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {currentField && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          {currentField.fieldId && (
            <Button onClick={() => removeField(currentField.fieldId)}>
              УДАЛИТЬ ПОЛЕ (С КОНЦАМИ)
            </Button>
          )}
          <TextField
            label="Название"
            value={currentField.label}
            onChange={(event) => {
              setCurrentField({ ...currentField, label: event.target.value });
            }}
          />
          <TextField
            label="Код (латиницей)"
            value={currentField.code}
            onChange={(event) => {
              setCurrentField({ ...currentField, code: event.target.value });
            }}
          />
          <TextField
            label="Пояснение (голубой текст)"
            value={currentField.subtitle}
            onChange={(event) => {
              setCurrentField({
                ...currentField,
                subtitle: event.target.value,
              });
            }}
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="type">Тип</InputLabel>
            <Select
              label="Тип"
              id="type"
              value={currentField.type}
              onChange={(event) => {
                setCurrentField({
                  ...currentField,
                  type: event.target.value as
                    | "text"
                    | "textarea"
                    | "select"
                    | "upload-music"
                    | "upload-image",
                });
              }}
            >
              <MenuItem value={"text"}>Строка</MenuItem>
              <MenuItem value={"textarea"}>Текст</MenuItem>
              <MenuItem value={"select"}>Выпадающий список</MenuItem>
              <MenuItem value={"upload-image"}>Загрузка изображения</MenuItem>
              <MenuItem value={"upload-music"}>Загрузка музыки</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="category">Категория</InputLabel>
            <Select
              label="Категория"
              id="category"
              value={currentField.category}
              onChange={(event) => {
                setCurrentField({
                  ...currentField,
                  category: event.target.value as
                    | "application"
                    | "application-media"
                    | "personal"
                    | "extra",
                });
              }}
            >
              <MenuItem value={"application"}>
                Информация о выступлении
              </MenuItem>
              <MenuItem value={"personal"}>Ваши данные</MenuItem>
              <MenuItem value={"application-media"}>Материалы заявки</MenuItem>
              <MenuItem value={"extra"}>Дополнительные материалы</MenuItem>
              <MenuItem value={"group"}>Команда</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentField.required}
                id="required"
                onChange={(event) => {
                  setCurrentField({
                    ...currentField,
                    required: event.target.checked,
                  });
                }}
                inputProps={{
                  "aria-label": "Обязательное поле",
                }}
              />
            }
            label="Обязательное поле"
          />
          {!currentField.dependsOn && (
            <FormControl fullWidth>
              <InputLabel htmlFor="dependant">Зависимое поле</InputLabel>
              <Select
                id="dependant"
                label="Зависимое поле"
                defaultValue=""
                value={dependantField?.fieldId ?? ""}
                onChange={(event) => {
                  debugger;
                  const updatedField = fields.find(
                    (f) => f.fieldId === event.target.value
                  );
                  if (updatedField) {
                    updatedField.dependsOn = currentField.fieldId;
                    setDependantField(updatedField);
                    saveField(updatedField);
                  }
                }}
              >
                <MenuItem value="">Выберите поле</MenuItem>
                {dependableFields.map((f) => {
                  return (
                    <MenuItem key={f.fieldId} value={f.fieldId}>
                      {f.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {currentField.dependsOn && (
            <div>
              {`Это поле зависит от ${
                fields.find((f) => f.fieldId === currentField.dependsOn)?.label
              }`}
            </div>
          )}

          <Button
            onClick={() => {
              if (currentField.fieldId) {
                saveField(currentField);
              } else {
                addNewField(currentField);
              }
            }}
            title="Save"
          >
            СОХРАНИТЬ
          </Button>
        </div>
      )}
    </div>
  );
}

export default FieldsPage;
