import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Page, getPages, postPage, putPage } from "../../services/pageService";
import AddIcon from "@mui/icons-material/Add";

function InfoPage() {
  const [pages, setPages] = useState<Page[]>([]);
  const [currentPage, setCurrentPage] = useState<Page>();
  const [newPageTitle, setNewPageTitle] = useState<string>();
  const [newPageRoute, setNewPageRoute] = useState<string>();

  const addNewPage = async (newPage: Page) => {
    await postPage(newPage);
    setNewPageTitle("");
    setNewPageRoute("");
    getAllPages();
  };

  const getAllPages = async () => {
    const pagesData = await getPages();
    setPages(pagesData);
  };

  const savePage = async (page: Page) => {
    await putPage(page.pageId ?? "", page);
    getAllPages();
  };

  useEffect(() => {
    getAllPages();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
        <h1>Страницы сайта</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <TextField
              style={{ flex: 1 }}
              label="Заголовок страницы"
              value={newPageTitle}
              onChange={(event) => {
                setNewPageTitle(event.target.value);
              }}
            />
            <TextField
              style={{ flex: 1 }}
              label="Адрес страницы"
              value={newPageRoute}
              onChange={(event) => {
                setNewPageRoute(event.target.value);
              }}
            />
            <Button
              onClick={() => {
                const newPage = {
                  title: newPageTitle ?? "",
                  route: newPageRoute ?? "",
                  content: "",
                };
                addNewPage(newPage);
              }}
              title="Добавить"
            >
              Добавить
              <AddIcon />
            </Button>
          </div>
        </div>
        <List style={{ overflowY: "scroll" }}>
          {pages.length > 0 &&
            pages.map((page) => (
              <ListItem key={page.route}>
                <ListItemButton
                  onClick={() => {
                    setCurrentPage(page);
                  }}
                >
                  <ListItemText primary={page.title} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </div>
      {currentPage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            width: "100%",
            gap: "20px",
          }}
        >
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <h1>Контент страницы</h1>
            <Button
              variant="outlined"
              href={`/${currentPage.route}`}
              target="_blank"
            >
              Перейти на страницу
            </Button>
          </div>

          <TextField
            style={{ flex: 1 }}
            label="Заголовок страницы"
            value={currentPage.title}
            onChange={(event) => {
              setCurrentPage({ ...currentPage, title: event.target.value });
            }}
          />
          <TextField
            style={{ flex: 1 }}
            label="Адрес страницы"
            value={currentPage.route}
            onChange={(event) => {
              setCurrentPage({ ...currentPage, route: event.target.value });
            }}
          />
          <TextField
            style={{ flex: 1 }}
            label="Номер в меню"
            value={currentPage.order ?? 0}
            onChange={(event) => {
              setCurrentPage({
                ...currentPage,
                order: parseInt(event.target.value),
              });
            }}
          />

          {/* <ReactQuill
            style={{ height: "80%" }}
            theme="snow"
            value={currentPage.content}
            onChange={(value) => {
              setCurrentPage({ ...currentPage, content: value });
            }}
          /> */}

          <TextareaAutosize
            value={currentPage.content}
            style={{ height: "-webkit-fill-available", overflow: "scroll" }}
            onChange={(event) => {
              setCurrentPage({ ...currentPage, content: event.target.value });
            }}
          />
          <Button
            variant="contained"
            style={{ marginTop: "40px" }}
            onClick={() => savePage(currentPage)}
          >
            Сохранить
          </Button>
        </div>
      )}
    </div>
  );
}

export default InfoPage;
