import { Button, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";
function Deadlines() {
  const [festivalDate, setFestivalDate] = useState("");
  const [festivalDateToSave, setFestivalDateToSave] = useState("");
  const [applicationsDate, setApplicationsDate] = useState("");
  const [applicationsDateToSave, setApplicationsDateToSave] = useState("");

  const getDeadlineRow = (
    id: string,
    label: string,
    currentLabel: string,
    value: string,
    valueToSave: string,
    setFn: React.Dispatch<React.SetStateAction<string>>,
    setToSaveFn: React.Dispatch<React.SetStateAction<string>>
  ) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <TextField
          id={id}
          label={label}
          placeholder="DD.MM.YYYY"
          value={value}
          onChange={(event) => {
            setFn(event.target.value);
          }}
        />
        <Button onClick={() => setToSaveFn(value)} title="Сохранить">
          <CheckIcon />
        </Button>
        <p>
          {currentLabel}: {valueToSave}
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      {getDeadlineRow(
        "festival-date",
        "Дата фестиваля",
        "Текущая дата фестиваля",
        festivalDate,
        festivalDateToSave,
        setFestivalDate,
        setFestivalDateToSave
      )}
      {getDeadlineRow(
        "applications-date",
        "Дедлайн заявок",
        "Текущая дата окончания приема заявок",
        applicationsDate,
        applicationsDateToSave,
        setApplicationsDate,
        setApplicationsDateToSave
      )}
    </div>
  );
}

export default Deadlines;
