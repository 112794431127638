import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MyApplication } from "../types";
import { getApplications } from "../../services/applicationsService";
import "./Applicants.css";

type DanceApplication = {
  subnomintaionId: string;
  name: string;
  city?: string;
  contact?: string;
  original?: string;
  demo?: string;
  comment?: string;
  helpers?: string;
  specPrize?: string;
  specEffects?: string;
};

function DanceApplications() {
  const [allApplications, setAllApplications] = useState<MyApplication[]>([]);
  const [danceApplications, setDanceApplications] = useState<
    DanceApplication[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllApplications = async () => {
    setIsLoading(true);
    const applicationsData = await getApplications();
    setAllApplications(applicationsData);

    const danceApplicationsTemp = applicationsData
      .filter(
        (a) =>
          a.subNominationId === "7d4054c2-11d2-4540-a8bf-8de1679f2e70" ||
          a.subNominationId === "9d809a57-ed2d-4feb-ab64-c07af49b22c1"
      )
      .map((a) => {
        return {
          subnomintaionId: a.subNominationId ?? "",
          name: a.fullName,
          city: a.applicationData?.find((d) => d.field.code === "city")?.value,
          contact: a.applicationData?.find((d) => d.field.code === "socmedia")
            ?.value,
          original: a.applicationData?.find(
            (d) => d.field.code === "original_dance_link"
          )?.value,
          demo: a.applicationData?.find((d) => d.field.code === "dance_demo")
            ?.value,
          comment: a.applicationData?.find((d) => d.field.code === "extra_info")
            ?.value,
          helpers: a.applicationData?.find((d) => d.field.code === "assistant")
            ?.value,
          specPrize: a.applicationData?.find(
            (d) => d.field.code === "special_prize"
          )?.value,
          specEffects: a.applicationData?.find((d) => d.field.code === "props")
            ?.value,
        };
      })
      .sort((a, b) => a.subnomintaionId.localeCompare(b.subnomintaionId));

    setDanceApplications(danceApplicationsTemp);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllApplications();
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "90%" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Subnomination</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Contact</TableCell>
              <TableCell align="right">Original</TableCell>
              <TableCell align="right">Demo</TableCell>
              <TableCell align="right">Comment</TableCell>
              <TableCell align="right">Helpers</TableCell>
              <TableCell align="right">SpecPrize</TableCell>
              <TableCell align="right">SpecEffects</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {danceApplications.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.subnomintaionId}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.city}</TableCell>
                <TableCell align="center">{row.contact}</TableCell>
                <TableCell align="center">{row.original}</TableCell>
                <TableCell align="center">{row.demo}</TableCell>
                <TableCell align="center">{row.comment}</TableCell>
                <TableCell align="center">{row.helpers}</TableCell>
                <TableCell align="center">{row.specPrize}</TableCell>
                <TableCell align="center">{row.specEffects}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DanceApplications;
