export const getDate = (date: string): Date => {
  // Split the date string into components
  var dateComponents = date.split(".");

  // Extract day, month, and year components
  var day = parseInt(dateComponents[0], 10);
  var month = parseInt(dateComponents[1], 10) - 1; // Months in JavaScript are zero-based (0-11)
  var year = parseInt(dateComponents[2], 10);

  // Create a Date object
  return new Date(year, month, day);
};
