import {
  MyApplication,
  Photocosplay,
  PhotocosplayAdmin,
  PostApplication,
} from "../admin/types";
import { BASE_URL } from "../config";
import { fetchWithAuth, uploadFileWithAuth } from "../utils/authUtils";

type ReturnFile = {
  fieldId: string;
  fieldName: string;
  value: string;
};

export const uploadFile = async (
  fieldId: string,
  fieldName: string,
  file: Blob
): Promise<ReturnFile> => {
  try {
    const response = await uploadFileWithAuth(`${BASE_URL}/files/upload`, file);

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
    const fileResponse = await response.json();
    return {
      fieldId: fieldId,
      value: fileResponse.fileName,
      fieldName: fieldName,
    };
  } catch (error) {
    return {
      fieldId: fieldId,
      value: "Ошибка загрузки",
      fieldName: fieldName,
    };
  }
};

export const postApplication = async (
  application: PostApplication
): Promise<{ success: boolean; errors?: string[] }> => {
  await uploadFiles(application);

  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications`,
      "POST",
      application
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
    return Promise.resolve({
      success: true,
      errors: application.fileUploadErrors,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({ success: false });
  }
};

const uploadFiles = async (application: PostApplication) => {
  if (!application.files) return;
  const uploadPromises: Promise<ReturnFile>[] = application.files.map((file) =>
    uploadFile(file.fieldId, file.fieldLabel, file.file)
  );

  await Promise.all(uploadPromises).then((responses) => {
    responses.forEach((r) => {
      const field = application.applicationData?.find(
        (f) => f.fieldId === r.fieldId
      );
      if (field) {
        if (r.value !== "Ошибка загрузки") {
          field.value = r.value;
        } else {
          field.value = "";
          application.fileUploadErrors = [
            ...(application.fileUploadErrors ?? []),
            r.fieldName,
          ];
        }
      }
    });
  });
};

export const putApplication = async (
  application: PostApplication
): Promise<{ success: boolean; errors?: string[] }> => {
  await uploadFiles(application);

  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications`,
      "PUT",
      application
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
    return Promise.resolve({
      success: true,
      errors: application.fileUploadErrors,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({ success: false });
  }
};
export const getMyApplications = async (): Promise<MyApplication[]> => {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications/userApplications`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as MyApplication[];
  } catch (error) {
    return Promise.resolve([]);
  }
};

export const getApplicationById = async (applicationId: string) => {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications/${applicationId}/applicationData`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.resolve([]);
  }
};

export async function setPending(applicationId: string): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications/${applicationId}/set-pending-state`,
      "POST",
      {}
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function setInvalid(
  applicationId: string,
  note: string
): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications/${applicationId}/set-invalid-state`,
      "POST",
      { note: note }
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function setAccepted(applicationId: string): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications/${applicationId}/accept`,
      "POST",
      {}
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function setDeclined(applicationId: string): Promise<void> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/applications/${applicationId}/deny`,
      "POST",
      {}
    );

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function getPhotocosplay(): Promise<Photocosplay[]> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/voting/getVotesForUser`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as Photocosplay[];
  } catch (error) {
    return Promise.resolve([]);
  }
}

export async function getPhotocosplayAdmin(): Promise<PhotocosplayAdmin[]> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/voting/getVotes`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as PhotocosplayAdmin[];
  } catch (error) {
    return Promise.resolve([]);
  }
}

export async function setPhotocosplayRating(
  applicationId: string,
  rating: number
): Promise<void> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/voting/vote`, "POST", {
      applicationId: applicationId,
      rating: rating,
    });

    if (!response.ok) {
      throw new Error(`POST Request Failed with status ${response.status}`);
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function getApplications(): Promise<MyApplication[]> {
  try {
    const response = await fetchWithAuth(`${BASE_URL}/applications`);

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as MyApplication[];
  } catch (error) {
    return Promise.resolve([]);
  }
}
