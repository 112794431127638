import React, { useEffect, useState } from "react";
import "./Contest.css";
import { useLocation, useParams } from "react-router-dom";
import { getPage } from "../services/pageService";

function Contest() {
  const [contestData, setContestData] = useState<string>("");
  const { pathname } = useLocation();

  useEffect(() => {
    async function fetchPage() {
      try {
        const page = await getPage(pathname);
        setContestData(page.content);
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
  }, [pathname]);

  return (
    <div className="contest longread">
      {contestData ? (
        <div className="content-section container-v center">
          <div className="content">
            <div
              className="longread-content container-v"
              dangerouslySetInnerHTML={{ __html: contestData }}
            ></div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contest;
