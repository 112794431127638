import React from "react";
import "./Countdown.css";

interface CountdownProps {
  title: string;
  days: number;
  hours: number;
  minutes?: number;
  seconds?: number;
  className?: string;
  small?: boolean;
}

const Countdown = ({
  title,
  days,
  hours,
  minutes,
  seconds,
  className,
  small = false,
}: CountdownProps) => {
  return (
    <div
      className={`text black-text show-timer ${
        small ? "text-small" : ""
      } ${className}`}
    >
      <div style={{ marginBottom: small ? 5 : 10, wordWrap: "normal" }}>
        {title}
      </div>
      <div className="container">
        {days != null && (
          <div className="container-v center timer-number">
            <div>{days}</div> <p>дней</p>
          </div>
        )}
        {hours != null && (
          <div className={small ? "timer-dot-small" : "timer-dot"}>⬤</div>
        )}
        {hours != null && (
          <div className="container-v center timer-number">
            <div>{hours}</div> <p>часов</p>
          </div>
        )}
        {minutes != null && (
          <div className={small ? "timer-dot-small" : "timer-dot"}>⬤</div>
        )}
        {minutes != null && (
          <div className="container-v center timer-number">
            <div>{minutes}</div> <p>минут</p>
          </div>
        )}
        {seconds != null && (
          <div className={small ? "timer-dot-small" : "timer-dot"}>⬤</div>
        )}
        {seconds != null && (
          <div className="container-v center timer-number">
            <div>{seconds}</div> <p>секунд</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Countdown;
