import React, { useEffect, useState } from "react";
import { confirmEmail } from "../services/auth.service";
import { useSearchParams } from "react-router-dom";

function EmailConfirmation() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    async function emailConfirmation() {
      setIsLoading(true);
      try {
        const code = searchParams.get("code");
        await confirmEmail(code ?? "");
        setIsSuccess(true);
      } catch (error) {
        setIsSuccess(false);
        // Handle error
        console.error("Email confirmation error:", error);
      }
      setIsLoading(false);
    }

    emailConfirmation();
  }, [searchParams]);

  return (
    <div className="contest longread">
      {!isLoading ? (
        <div className="content-section container-v center">
          <div className="content">
            <div className="longread-content container-v">
              {isSuccess ? (
                <>
                  <h2 style={{ textAlign: "center" }}>
                    Ваш email успешно подтвержден!
                  </h2>
                  <a
                    className="button apply-button"
                    style={{ color: "#ffffff", marginTop: "30px" }}
                    href="/"
                  >
                    НА ГЛАВНУЮ
                  </a>
                </>
              ) : (
                <>
                  <h2 style={{ textAlign: "center" }}>
                    Что-то пошло не так :(
                  </h2>
                  <a
                    className="button apply-button"
                    style={{ color: "#ffffff", marginTop: "30px" }}
                    href="/"
                  >
                    НА ГЛАВНУЮ
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailConfirmation;
