import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPage } from "../services/pageService";

function Info() {
  const [infoData, setInfoData] = useState<string>("");
  const { pathname } = useLocation();

  useEffect(() => {
    async function fetchPage() {
      try {
        const page = await getPage(pathname);
        setInfoData(page.content);
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
  }, [pathname]);

  return (
    <div className="contest longread">
      <div className="content-section container-v center">
        <div className="content">
          {infoData ? (
            <div
              className="longread-content container-v"
              dangerouslySetInnerHTML={{ __html: infoData }}
            ></div>
          ) : (
            <div style={{ height: "100vh" }}>
              <div className="loading-overlay">
                <div className="spinner"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Info;
