import React, { useState } from "react";
import "./LogoSection.css";
import FestivalCountdown from "../FestivalCountdown";
import ApplicationsCountdown from "../ApplicationsCountdown";
import { getDate } from "../../helpers";

const LogoSection = () => {
  const applicationsStart = "20.10.2023";
  const applicationsEnd = "27.11.2023";
  const fest = new Date().getTime() >= getDate("16.12.2023").getTime();

  let applicationsTitle = "До начала приема заявок:";
  let applicationDate = applicationsStart;

  if (getDate(applicationsStart).getTime() - new Date().getTime() < 0) {
    applicationsTitle = "До окончания приема заявок:";
    applicationDate = applicationsEnd;
  }
  return (
    <div
      className="screen-section color-section container-v center"
      style={{ position: "relative" }}
    >
      <img className="clouds" src="Clouds.png" alt="NYAF" />
      <div
        className="container center logo-container content"
        style={{ position: "relative" }}
      >
        <div className="left-part">
          <div className="container" style={{ alignItems: "flex-end" }}>
            <img className="big-logo" src="NYAF.svg" alt="NYAF" />
            <img className="sub-logo" src="23.svg" alt="23" />
          </div>
          <div
            className="text black-text"
            style={{
              minWidth: "290px",
              marginTop: "11px",
              whiteSpace: "nowrap",
            }}
          >
            16 декабря • Уфа • ДК «Орджоникидзе»
          </div>

          {!fest && (
            <button
              onClick={() => {
                window.location.pathname = "/tickets";
              }}
              className="text buy-ticket-button wrap"
            >
              КУПИТЬ БИЛЕТ
            </button>
          )}
          {fest && (
            <>
              <button
                onClick={() => {
                  window.location.pathname = "/schedule";
                }}
                className="text buy-ticket-button wrap"
                style={{
                  marginTop: "55px",
                  marginBottom: "5px",
                  fontSize: "25px",
                  padding: "20px 38px",
                  borderRadius: "15px",
                }}
              >
                ПРОГРАММА ФЕСТИВАЛЯ
              </button>
              <div
                className="text black-text"
                style={{
                  minWidth: "290px",
                  marginTop: "11px",
                  whiteSpace: "nowrap",
                  fontSize: "20px",
                }}
              >
                Программа фестиваля, зрительское <br /> голосование, карта ДК и{" "}
                <br /> полезная информация
              </div>
            </>
          )}

          {!fest && <FestivalCountdown targetDate={"16.12.2023"} />}
        </div>
        <div className="image-container-bg">
          <img className="image" src="Mascot2.png" alt="mascot" />
          <ApplicationsCountdown
            targetDate={applicationDate}
            title={applicationsTitle}
          />
        </div>
      </div>
      <div className="mobile-section container-v center">
        <div className="image-container-bg-mobile">
          <img className="image" src="Mascot2.png" alt="mascot" />
          <ApplicationsCountdown
            targetDate={applicationDate}
            title={applicationsTitle}
          />
          <img className="clouds-mobile" src="Clouds.png" alt="NYAF" />
        </div>

        <div className="container-v center mobile-logo">
          <div>
            <img className="big-logo" src="NYAF_pink.svg" alt="NYAF" />
            <img className="sub-logo" src="23.svg" alt="23" />
          </div>
          <div className="text black-text">
            16 декабря • Уфа • ДК «Орджоникидзе»
          </div>

          {!fest && (
            <button
              className="text button buy-ticket-button wrap"
              onClick={() => {
                window.location.pathname = "/tickets";
              }}
            >
              КУПИТЬ БИЛЕТ
            </button>
          )}
          {fest && (
            <>
              <button
                className="text button buy-ticket-button wrap"
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  window.location.pathname = "/schedule";
                }}
              >
                ПРОГРАММА ФЕСТИВАЛЯ
              </button>
              <div
                className="text black-text"
                style={{
                  minWidth: "290px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                Программа фестиваля, зрительское <br /> голосование, карта ДК и{" "}
                <br /> полезная информация
              </div>
            </>
          )}
          {!fest && <FestivalCountdown targetDate={"16.12.2023"} />}
        </div>
      </div>
    </div>
  );
};

export default LogoSection;
