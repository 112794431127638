import { Box, Checkbox, Divider, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getSetting, postSetting } from "../../services/settingsService";

function ApplicationsSettings() {
  const [showAccepted, setShowAccepted] = useState<boolean>(false);

  const setConfigValue = async (key: string, value: string) => {
    await postSetting(key, value);
    await getShowAccepted();
  };

  const getConfigValue = async (key: string) => {
    const value = await getSetting(key);
    return value;
  };

  const getShowAccepted = async () => {
    const value = await getConfigValue("showAccepted");
    setShowAccepted(value as unknown as boolean);
  };

  useEffect(() => {
    getShowAccepted();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "100%" }}>
      <Box sx={{ width: "400px", padding: "10px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAccepted}
              id="required"
              onChange={(event) => {
                setConfigValue(
                  "showAccepted",
                  event.target.checked ? "true" : "false"
                );
              }}
              inputProps={{
                "aria-label": "Показывать результаты отбора",
              }}
            />
          }
          label="Показывать результаты отбора"
        />
      </Box>
      <Divider orientation="vertical" />
      <Outlet />
    </div>
  );
}

export default ApplicationsSettings;
