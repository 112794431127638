import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ApplicationLike, Block } from "../types";
import { getBlocks, getLikesSummary } from "../../services/scheduleService";

function UserVoteAdmin() {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [likeSummary, setLikeSummary] = useState<ApplicationLike[]>([]);

  const getAllBlocks = async () => {
    const likesS = await getLikesSummary();
    setLikeSummary(likesS);
    const blocksData = await getBlocks();
    let time = new Date(2023, 1, 1, 12, 0, 0, 0).getTime();
    const orderedBlocks = blocksData
      .sort((a, b) => a.order - b.order)
      .map((b) => {
        const startTime = new Date(time);
        let newBlock = {
          ...b,
          duration: b.durationInSeconds / 60,
          startTime: `${startTime.getHours()}:${
            startTime.getMinutes() < 10
              ? "0" + startTime.getMinutes()
              : startTime.getMinutes()
          }`,
        };
        time += b.durationInSeconds * 1000;
        return newBlock;
      });
    setBlocks(orderedBlocks);
  };

  const getLikes = () => {
    const likes: any[] = [];
    blocks.forEach((b) => {
      const likedScheduleItems = b.scheduleItems.map((i) => {
        return {
          ...i,
          voteCount: likeSummary.find(
            (s) => s.applicationId === i.applicationId
          )?.votecount,
        };
      });
      if (likedScheduleItems.length > 0) {
        const nomination = likes.find((l) => l.name === b.name);
        if (nomination === undefined) {
          likes.push({
            name: b.name,
            likes: likedScheduleItems,
          });
        } else {
          nomination.likes = [...nomination.likes, ...likedScheduleItems];
        }
      }
    });
    return (
      <div className="container-v">
        {likes.map((l) => {
          return (
            <div className="container-v">
              <div className="text">{l.name}</div>
              {l.likes
                .sort((a: any, b: any) => b.voteCount - a.voteCount)
                .map((li: any) => {
                  return getScheduleItem(li);
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const getScheduleItem = (item: any) => {
    return (
      <div key={item.scheduleItemId} className="container schedule-item">
        <div className="container" style={{ gap: "10px" }}>
          <div>{item.name}</div>
          <div className="text">{item.voteCount}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAllBlocks();
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "100%" }}>
      <Box sx={{ width: "700px", padding: "10px" }}>{getLikes()}</Box>
    </div>
  );
}

export default UserVoteAdmin;
