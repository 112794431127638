import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MyApplication, Nomination, UserApplications } from "../types";
import { getApplications } from "../../services/applicationsService";
import "./Applicants.css";
import {
  getNominations,
  getNominationsWithApplications,
} from "../../services/nominationsService";

function Applicants() {
  const [allApplications, setAllApplications] = useState<MyApplication[]>([]);
  const [userApplications, setUserApplications] = useState<UserApplications[]>(
    []
  );
  const [nominations, setNominations] = useState<
    { subNominationId: string; name: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllNominations = async () => {
    const nominationsData = await getNominationsWithApplications();
    const subnominationsDictionary: any[] = [];
    nominationsData.forEach((n) => {
      n.subNominations?.forEach((s) => {
        subnominationsDictionary.push({
          subNominationId: s.subNominationId,
          name: `${n.name}: ${s.name}`,
        });
      });
    });
    setNominations(subnominationsDictionary);
    return subnominationsDictionary;
  };

  const getAllApplications = async () => {
    setIsLoading(true);
    const nominationsData = await getAllNominations();
    const applicationsData = await getApplications();
    const acceptedAplications = applicationsData.filter((a) => a.state === 4);
    setAllApplications(acceptedAplications);
    const userApplicationsTemp: UserApplications[] = [];
    acceptedAplications.forEach((a) => {
      const people = a.applicationData
        ?.find((f) => f.field.code === "fio_group" || f.field.code === "FIO")
        ?.value?.split(",")
        .map((v) => v.trim());

      people?.forEach((p) => {
        const person = userApplicationsTemp.find((pr) => pr.name === p);
        if (person) {
          person.applications.push({
            name: a.fullName,
            state: a.state,
            nominationName: nominationsData.find(
              (n) => n.subNominationId === a.subNominationId
            )?.name,
          });
        } else {
          userApplicationsTemp.push({
            name: p,
            city: a.applicationData
              ?.find((f) => f.field.code === "city")
              ?.value.trim(),
            applications: [
              {
                name: a.fullName,
                state: a.state,
                nominationName: nominationsData.find(
                  (n) => n.subNominationId === a.subNominationId
                )?.name,
              },
            ],
          });
        }
      });
    });
    setUserApplications(
      userApplicationsTemp.sort((a, b) => a.name.localeCompare(b.name))
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getAllApplications();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "90%" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Applicant's name</TableCell>
              <TableCell align="left">City</TableCell>
              <TableCell align="left">Applications</TableCell>
              <TableCell align="left">Number of Applications</TableCell>
              <TableCell align="left">Number of Accepted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userApplications.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`${row.name}`}
                </TableCell>
                <TableCell align="left">{row.city}</TableCell>
                <TableCell align="left">
                  <div className="container-v">
                    {row.applications.map((a, index) => (
                      <div className="container" style={{ textAlign: "start" }}>
                        <strong>{`${index + 1}. ${
                          a.nominationName
                        } - `}</strong>
                        {`${a.name}`}
                      </div>
                    ))}
                  </div>
                </TableCell>
                <TableCell align="left">{row.applications.length}</TableCell>
                <TableCell align="left">
                  {row.applications.filter((a) => a.state === 4).length}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Applicants;
