import React, { ReactNode, useEffect, useState } from "react";
import "./MyApplications.css";
import { MyApplication } from "../admin/types";
import { isAccessTokenValid } from "../utils/authUtils";
import { getMyApplications } from "../services/applicationsService";
import { getSetting } from "../services/settingsService";

function MyApplications() {
  const [applicationsData, setApplicationData] = useState<MyApplication[]>();
  const [showAccepted, setShowAccepted] = useState<boolean>(false);

  const getConfigValue = async (key: string) => {
    const value = await getSetting(key);
    return value;
  };

  const getShowAccepted = async () => {
    const value = await getConfigValue("showAccepted");
    setShowAccepted(value as unknown as boolean);
  };

  useEffect(() => {
    async function fetchPage() {
      try {
        const applications = await getMyApplications();
        setApplicationData(applications);
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
    getShowAccepted();
    // eslint-disable-next-line
  }, []);

  const getStatusName = (status: number) => {
    switch (status) {
      case 2:
        return "На рассмотрении";
      case 1:
        return "Не хватает материалов";
      case 4:
        return "Принята";
      case 3:
        return "Отклонена";

      default:
        return "Новая";
    }
  };

  const getApplicationCard = (application: MyApplication): ReactNode => {
    const state = showAccepted
      ? application.state
      : application.state === 4 || application.state === 3
      ? 2
      : application.state;
    return (
      <div
        className={`container-v application-card application-${state}`}
        // onClick={() => {
        //   window.location.href = `/apply?nominationId=${application.subNomination.nomination?.nominationId}&applicationId=${application.applicationId}`;
        // }}
      >
        <p
          className="container application-text"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <strong>
            Заявка
            {`${
              application.applicationDate
                ? " от " +
                  new Date(application.applicationDate).toLocaleDateString()
                : ""
            }`}
          </strong>
          <span>{getStatusName(state)}</span>
        </p>
        <p className="text application-text">{`${
          (application.subNomination as any)?.nomination?.name
        }${
          (application.subNomination &&
            application.subNomination?.name !==
              (application.subNomination as any)?.nomination?.name) ??
          ""
            ? ": " + application.subNomination?.name
            : ""
        }`}</p>
        <p className="text application-text">{`${application.fullName}`}</p>
        {application.adminNote && application.state === 1 && (
          <div className="container-v">
            <p>
              <strong>Комментарий координатора: </strong>
            </p>
            <p>{application.adminNote}</p>
          </div>
        )}
      </div>
    );
  };

  const getLogInMessage = () => {
    return (
      <div className="container-v" style={{ alignItems: "flex-start" }}>
        <h2>Для просмотра ваших заявок необходимо авторизоваться на сайте!</h2>
      </div>
    );
  };

  return (
    <div className="contest longread">
      {applicationsData ? (
        <div className="content-section container-v center">
          <div className="content longread-content">
            {isAccessTokenValid() ? (
              <>
                <h1>МОИ ЗАЯВКИ</h1>
                <h2>Мои заявки</h2>
                <div
                  className="container-v"
                  style={{ width: "100%", gap: "15px" }}
                >
                  {applicationsData.map((a) => {
                    return getApplicationCard(a);
                  })}
                </div>
              </>
            ) : (
              getLogInMessage()
            )}
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyApplications;
