import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { Nomination, SubNomination } from "../types";
import {
  getNominations,
  getSubNominations,
  postNomination,
  postSubNomination,
  putNomination,
} from "../../services/nominationsService";

function NominationsPage() {
  const [nominationValue, setNominationValue] = useState("");
  const [subNominationValue, setSubNominationValue] = useState("");
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const [currentNomination, setCurrentNomination] = useState<Nomination>();
  const [currentSubNominations, setCurrentSubNominations] = useState<
    SubNomination[]
  >([]);

  const addNewNomination = async (newNomination: Nomination) => {
    await postNomination(newNomination);
    getAllNominations();
  };

  const addNewSubNomination = async (newSubNomination: SubNomination) => {
    if (currentNomination) {
      await postSubNomination(newSubNomination, currentNomination);
      getCurrentSubNominations(currentNomination?.nominationId);
    }
  };

  const getAllNominations = async () => {
    const nominationsData = await getNominations();
    setNominations(nominationsData);
  };

  const getCurrentSubNominations = async (nominationId?: string) => {
    if (nominationId) {
      const subNominationsData = await getSubNominations(nominationId);
      setCurrentSubNominations(subNominationsData);
    }
  };

  const saveNomination = async (nomination: Nomination) => {
    await putNomination(nomination.nominationId ?? "", nomination);
    getAllNominations();
  };

  useEffect(() => {
    getAllNominations();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        width: "100%",
        gap: "20px",
      }}
    >
      <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
        <h1>Основные номинации</h1>
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <TextField
            style={{ flex: 1 }}
            label="Добавить номинацию"
            value={nominationValue}
            onChange={(event) => {
              setNominationValue(event.target.value);
            }}
          />
          <Button
            onClick={() => {
              const newNom = {
                name: nominationValue,
              };
              addNewNomination(newNom);
            }}
            title="Добавить"
          >
            <AddIcon />
          </Button>
        </div>
        <List>
          {nominations.map((nom) => (
            <ListItem key={nom.nominationId}>
              <ListItemButton
                onClick={() => {
                  setCurrentNomination(nom);
                  getCurrentSubNominations(nom.nominationId);
                }}
              >
                <ListItemText primary={nom.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {currentNomination && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1>Редактировать номинацию</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <TextField
              style={{ flex: 1 }}
              label="Название"
              value={currentNomination.name}
              onChange={(event) => {
                setCurrentNomination({
                  ...currentNomination,
                  name: event.target.value,
                });
              }}
            />
            <TextField
              style={{ flex: 1 }}
              label="Шаблон имени выступления"
              value={currentNomination.fullNameTemplate}
              onChange={(event) => {
                setCurrentNomination({
                  ...currentNomination,
                  fullNameTemplate: event.target.value,
                });
              }}
            />
            <Button
              onClick={() => {
                saveNomination(currentNomination);
              }}
              title="Сохранить"
            >
              СОХРАНИТЬ
            </Button>
          </div>
          <h1>Подноминации {currentNomination.name}</h1>
          <div style={{ display: "flex", gap: "10px" }}>
            <TextField
              label="Добавить подноминацию"
              value={subNominationValue}
              onChange={(event) => {
                setSubNominationValue(event.target.value);
              }}
            />
            <Button
              onClick={() => {
                const newSubNom = {
                  name: subNominationValue,
                  nominationId: currentNomination?.nominationId ?? "0",
                };

                addNewSubNomination(newSubNom);
                setSubNominationValue("");
              }}
              title="Добавить"
            >
              <AddIcon />
            </Button>
          </div>
          <List>
            {currentSubNominations.map((nom) => (
              <ListItem key={nom.subNominationId}>
                <ListItemText primary={nom.name} />
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default NominationsPage;
