import React, { useEffect, useState } from "react";
import "./LoginForm.css";
import { BASE_URL } from "../config";

interface LoginFormProps {
  onLogin: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isDesktop = window.screen.width > 1450;
  useEffect(() => {
    const vk_widget = document.getElementById("vk_auth");
    if (!vk_widget) {
      //Create the vk_groups div element
      const vkAuthDiv = document.createElement("div");
      vkAuthDiv.id = "vk_auth";
      // Append the vk_groups div element to the component's root element or another container element as needed
      const container = document.getElementById("login"); // Replace 'your-container-id' with the actual container ID
      container?.appendChild(vkAuthDiv);
      // Create the script element for the VK.Widgets.Group
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      // scriptElement.innerHTML = `
      //   VK.Widgets.Auth("vk_auth", {"width":${
      //     isDesktop ? 300 : 200
      //   },"authUrl":"/loginFromVk"});
      // `;

      scriptElement.innerHTML = `VK.Widgets.Auth("vk_auth", {"width": ${
        isDesktop ? 300 : 200
      }, "onAuth": function(data){
        fetch("${BASE_URL}/auth/login-from-vk", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.ok) {
            const r = await response.json();
            localStorage.setItem("accessToken", r.accessToken);
            localStorage.setItem("uid-vk", data.uid ?? "");

            window.location.href = "/";
          } else {
            throw new Error("Login failed");
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
          window.location.href = "/";
        });}});`;
      //Append the script element to the component's root element or another container element as needed
      container?.appendChild(scriptElement);
    }
  }, [isDesktop]); // The empty dependency array ensures this code runs once after the component mounts

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        // Login successful
        const data = await response.json();
        const accessToken = data.accessToken;

        // Save the access token to localStorage
        localStorage.setItem("accessToken", accessToken);
        onLogin();
      } else {
        // Login failed
        const data = await response.json();
        setError(data.message);
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("An error occurred while logging in.");
    }
    setIsLoading(false);
  };

  const getLoginForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <label className="form-label" htmlFor="email">
          Email
        </label>
        <input
          className="form-input"
          type="text"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label
          className="form-label"
          htmlFor="password"
          style={{ marginTop: "10px" }}
        >
          Пароль
        </label>
        <input
          className="form-input"
          type="password"
          id="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit" style={{ width: "200px" }}>
          {isLoading ? <div className="spinner spinner-small"></div> : "ВОЙТИ"}
        </button>
        {error && <div className="text">{error}</div>}
        <div className="text" style={{ marginTop: "10px" }}>
          <span
            className="register-link"
            onClick={() => {
              setShowLogin(false);
            }}
          >
            Назад
          </span>
        </div>
      </form>
    );
  };

  return (
    <div className="container-v center" style={{ gap: "20px" }}>
      {!showLogin ? (
        <>
          <div
            className="container center"
            style={{ width: "100%" }}
            id="login"
          ></div>
          <div className="text black-text">ИЛИ</div>
          <div
            className="button"
            onClick={() => {
              setShowLogin(true);
            }}
          >
            ВОЙТИ С ЛОГИНОМ И ПАРОЛЕМ
          </div>
        </>
      ) : (
        getLoginForm()
      )}
    </div>
  );
};

export default LoginForm;
